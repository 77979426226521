import React from 'react'

import PageLayout from '../../layouts/PageLayout';
import Section from '../../components/Section';
import Seo from '../../components/Seo';

import styles from './ClinicalNeurosciences.module.scss';

import WhoWeAre from '../IndexPage/sections/WhoWeAre';
import Testimonials from '../IndexPage/sections/Testimonials';

import icon from './icon.png';
import img1 from './img1.png';
import img2 from './img2.png';
import img3 from './img3.png';
import img4 from './img4.png';
import motion from './motion.png';
import publication from './publication1.png';

const ClinicalNeurosciences = () => {
  return (
    <PageLayout>
      <Seo title="Cursor Insight | Human Motion Analysis Technology in
      Clinical Neurosciences" />
      <div className={styles.ClinicalNeurosciences}>
        <Section
          title="Human Motion Analysis Technology in Clinical Neurosciences"
        >
          <div className="container">
            <div className="row mb-5 pb-5">
              <div className="col-12 text-center">
                <div className={styles.Intro}>
                  <div className={styles.Card}>
                    <img src={icon} alt="icon" />
                  </div>
                  <p>
                    <strong>Partner:</strong>
                    {' '}
                    Hungarian National Institute of Clinical Neurosciences
                  </p>
                  <p>
                    <strong>Goal:</strong>
                    {' '}
                    Enhance clinical assessments and research with motion
                    analysis AI tools
                  </p>
                  <p>
                    <strong>Solution:</strong>
                    {' '}
                    Motion Lab supported by
                    {' '}
                    <a href="https://cursorinsight.com" target="_blank">
                      Cursor Insight
                    </a>
                  </p>
                  <a
                    href="https://calendly.com/tamas-cursor-insight/consultation
                    "
                    target="_blank"
                    className="ButtonPrimary mt-3"
                  >
                    Schedule a call
                  </a>
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-lg-6">
                <div className={styles.Element}>
                  <h2>Challenge</h2>
                  <p>
                    Human motion data is highly valuable, enabling predictions
                    and insights. Analyzing diverse datasets from multiple
                    sensors demands sophisticated techniques. Streamlining the
                    translation of raw motion data into actionable insights for
                    clinicians is crucial. Effective utilization necessitates
                    interdisciplinary collaboration among experts in
                    neurosciences, programming, and AI analytics.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className={styles.Element}>
                  <img src={img1} alt="img1" />
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12 text-center">
                <div className={styles.Element}>
                  <h2>Solution</h2>
                  <p>
                    We have an ongoing partnership with the National Institute
                    of Mental Health, Neurology, and Neurosurgery (Budapest,
                    Hungary), where we provide the hardware and software
                    infrastructure for a dedicated Motion Lab, and we take
                    charge of the motion data analysis tasks. This lab includes
                    a 12-camera optical motion capture system, a treadmill, a
                    wearable eye tracker, and tablet + pen workstations that
                    can assess both gross and fine motor skills during
                    neurological assessments. With these resources and support
                    in place, we can efficiently collect and analyze clinical
                    motion data to gain insights that matter. The first
                    tangible result of our collaboration was a study assessing
                    MCI from cursor movement data, which we published
                    {' '}
                    <a
                      href="https://www.sciencedirect.com/science/article/pii/S235291482200257X"
                      target="_blank"
                    >
                      in this journal.
                    </a>
                  </p>
                </div>
                <div className={styles.Images}>
                  <img src={img2} alt="img2" />
                  <img src={img3} alt="img3" />
                  <img src={img4} alt="img4" />
                </div>
                <a
                  href="https://calendly.com/tamas-cursor-insight/consultation"
                  target="_blank"
                  className="ButtonPrimary mt-5"
                >
                  Schedule a call
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className={styles.Element}>
                  <h2 className="text-center">
                    Technologies used in the Motion Lab
                  </h2>
                  <a href="/MotionAnalysisHealth" target="_blank">
                    <img
                      src={motion}
                      alt="Motion Analysis"
                      className={styles.Motion}
                    />
                  </a>
                  <div className="row text-center">
                    <div className="col-lg-4">
                      <h3>Parkinson's Assessment</h3>
                      <p>
                        See how we automated the scoring of the Unified
                        Parkinson’s Disease Rating Scale (UPDRS) assessment by
                        utilizing video recordings of patients.
                      </p>
                      <iframe
                        src="https://player.vimeo.com/video/822017736?h=
                        092372aaed"
                        width="540"
                        height="315"
                        frameborder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowfullscreen
                      >
                      </iframe>
                    </div>
                    <div className="col-lg-4">
                      <h3>Dementia Detection</h3>
                      <p className="mb-5">
                        We achieved 79.8% accuracy in a study predicting Mild
                        Cognitive Impairment (MCI) purely based on cursor
                        movement analysis. We published our results in
                        {' '}
                        <a
                          href="https://www.sciencedirect.com/science/article/pii/S235291482200257X"
                          target="_blank"
                        >
                          this journal.
                        </a>
                      </p>
                      <a
                        href="https://www.sciencedirect.com/science/article/pii/S235291482200257X"
                        target="_blank"
                      >
                        <img
                          src={publication}
                          alt="Publication"
                          className={styles.Publication}
                        />
                      </a>
                    </div>
                    <div className="col-lg-4">
                      <h3>3D Body<br/>Modeling</h3>
                      <p>
                        See how we model the human body in 3D with only 2 or 3
                        ordinary cameras and then recognize people by analyzing
                        their body movement.
                      </p>
                      <iframe
                        src="https://player.vimeo.com/video/883181847?h=729aaec57b"
                        width="540"
                        height="315"
                        frameborder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowfullscreen
                      >
                      </iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <Testimonials />
        <WhoWeAre />
      </div>
    </PageLayout>
  );
}

export default ClinicalNeurosciences;
