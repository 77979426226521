import { Link } from 'react-router-dom';
import Slider from 'react-slick';

import Section from '../../../../components/Section';
import { ContainerSpinner } from '../../../../components/Spinner';
import usePosts from '../../hooks/usePosts';
import ErrorMessage from '../ErrorMessage';
import PostCard from '../PostCard';
import styles from './BlogTeaser.module.scss';
import './Slider.scss';

const SETTINGS = {
  dots: true,
  arrows: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  infinite: true,
  swipeToSlide: true,
  speed: 200,
  responsive: [
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const BlogTeaser = () => {
  const { isLoading, posts, error } = usePosts({ limit: 3 });

  const renderedPosts = posts.map((post) => (
    <PostCard key={post.id} post={post} className="px-3" />
  ));

  return (
    <Section title="Recent blog posts" className="mb-5 Slider">
      <>
        <div className={styles.Subtitle}>
          <p style={{ color: 'white', fontSize: '20px', textAlign: 'center' }}>
            Learn more about our technology.
            {' '}
            <Link to="/blog" className="mainlink">Join the discussion.</Link>
          </p>
        </div>

        {(!isLoading && error) && <ErrorMessage content={error.toString()} />}

        {isLoading
          ? <ContainerSpinner />
          : (
            <div className="row">
              <Slider {...SETTINGS} className="h-100">
                {renderedPosts}
              </Slider>
            </div>
          )
        }

        {isLoading || (
          <div className="row text-center mt-3 mt-lg-4">
            <div className="col-12">
              <Link to="/blog" className="ButtonPrimary">
                All blog posts
              </Link>
            </div>
          </div>
        )}
      </>
    </Section>
  );
};

export default BlogTeaser;
