import React from 'react';
import Slider from 'react-slick';

import Section from '../../../../components/Section';
import styles from './Partners.module.scss';
import './PartnerSlider.scss';

import partner1 from '../../../../images/partner-otp.png';
import partner2 from '../../../../images/partner-konica.png';
import partner3 from '../../../../images/partner-decathlon.png';
import partner4 from '../../../../images/partner-dsk.png';
import partner5 from '../../../../images/partner-cardiff.png';
import partner6 from '../../../../images/partner-mokk.png';
import partner7 from '../../../../images/partner-prezi.png';
import partner8 from '../../../../images/partner-sanofi.png';
import partner9 from '../../../../images/partner-hungaro.png';
import partner10 from '../../../../images/partner-natwest.png';
import partner11 from '../../../../images/partner-catapult.png';
import partner12 from '../../../../images/partner-wiseguys.png';
import partner13 from '../../../../images/partner-erlang.png';
import partner14 from '../../../../images/partner-neurology.png';
import partner15 from '../../../../images/partner-microsoft.png';

const SETTINGS = {
  speed: 6000,
  autoplay: true,
  autoplaySpeed: 0,
  cssEase: 'linear',
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  arrows: false,
};

const Partners = () => (
  <Section
    className={styles.Partners}
    title="Our partners"
  >
    <div className="row mt-5">
      <div className="col-12">
        <Slider {...SETTINGS} className="PartnerSlider">
          <div className={styles.SliderItem}>
            <div>
              <img src={partner1} alt="Partner" />
            </div>
          </div>

          <div className={styles.SliderItem}>
            <div>
              <img src={partner4} alt="Partner" />
            </div>
          </div>

          <div className={styles.SliderItem}>
            <div>
              <img src={partner3} alt="Partner" />
            </div>
          </div>

          <div className={styles.SliderItem}>
            <div>
              <img src={partner2} alt="Partner" />
            </div>
          </div>

          <div className={styles.SliderItem}>
            <div>
              <p>
                <img src={partner6} alt="Partner" />
                {' '}
                <span>
                  Association of Notaries
                </span>
              </p>
            </div>
          </div>

          <div className={styles.SliderItem}>
            <div>
              <div className={styles.SliderItem}>
                <img src={partner8} alt="Partner" />
              </div>
            </div>
          </div>

          <div className={styles.SliderItem}>
            <div>
              <img src={partner5} alt="Partner" />
            </div>
          </div>

          <div className={styles.SliderItem}>
            <div>
              <img src={partner7} alt="Partner" />
            </div>
          </div>

          <div className={styles.SliderItem}>
            <div>
              <img src={partner9} alt="Partner" />
            </div>
          </div>

          <div className={styles.SliderItem}>
            <div className="p-0">
              <p className="my-0">
                <img src={partner14} alt="Partner" className="mx-auto mb-2" />
                {' '}
                <p className="my-0 d-none d-md-block">
                  National Institue of Mental Health,
                  <br/>
                  Neurology and Neurosurgery
                </p>
              </p>
            </div>
          </div>

          <div className={styles.SliderItem}>
            <div>
              <p className="my-0">
                <img src={partner10} alt="Partner" />
                {' '}
                <p className="mt-3 mb-0">
                  Fintech Accelerator
                </p>
              </p>
            </div>
          </div>

          <div className={styles.SliderItem}>
            <div>
              <img src={partner12} alt="Partner" />
            </div>
          </div>

          <div className={styles.SliderItem}>
            <div>
              <img src={partner15} alt="Partner" />
            </div>
          </div>

          <div className={styles.SliderItem}>
            <div>
              <img src={partner11} alt="Partner" />
            </div>
          </div>

          <div className={styles.SliderItem}>
            <div>
              <img src={partner13} alt="Partner" />
            </div>
          </div>
        </Slider>
      </div>
    </div>
  </Section>
);

export default Partners;
