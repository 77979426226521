import PropTypes from 'prop-types';

import BaseLayout from './BaseLayout';
import Header from '../components/Header';

const PageLayout = ({ children }) => (
  <BaseLayout>
    <Header />
    <main>{children}</main>
  </BaseLayout>
);

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageLayout;
