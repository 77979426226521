import Section from '../../../components/Section';
import Awards from '../../../components/Awards';

const WhoWeAre = () => (
  <Section title="Who we are" className="pt-md-5 my-md-5">
    <div className="row">
      <div className="col-12">
        <p style={{ color: 'white', fontSize: '20px', textAlign: 'center' }}>
          We at Cursor Insight are experts in AI-backed human movement analysis.
          Having invested a combined 100+ person-years into developing
          AI&nbsp;prediction models, we are leaders in our field.
          <br/>
          <br/>
          Our award-winning
          machine learning technology is capable of identifying and classifying
          users by learning their unique movement patterns while they interact
          with a computer or a phone or appear on video.
          Based in the UK and
          Hungary, we build biometric applications across banking, forensics,
          cybersecurity and healthcare, serving millions of users.
        </p>
      </div>
    </div>
    <Awards />
  </Section>
);

export default WhoWeAre;
