import React from 'react';
import { SubscriptionForm } from 'custom-form-client/react';

import Seo from '../../../../components/Seo';
import Spinner from '../../../../components/Spinner';
import usePost from '../../hooks/usePost';
import CopyLinkButton from '../CopyLinkButton';
import Avatar from '../Avatar/Avatar';
import ErrorMessage from '../ErrorMessage';
import Tags from '../Tags';
import styles from './Post.module.scss';
import formStyles from './SubscriptionForm.module.scss';
//import '../../blog.scss';

const Post = () => {
  const { isLoading, post, error } = usePost();

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <Seo
        title={post.title.text}
        description="Graboxy Blog"
        image={post.featuredImage}
      />

      {error && <ErrorMessage content={error.toString()} />}

      <article className={styles.Post}>
        <div className="blogpost">
          <h1 dangerouslySetInnerHTML={{ __html: post.title.rendered }} />

          <div className={styles.PostRow}>
            <Tags tags={post.tags} />
            <div className={styles.PostDots} />
            <div className="align-self-center">
              <CopyLinkButton />
            </div>
          </div>

          <div className={styles.PostRow}>
            <span className="align-self-center">
              <Avatar src={post.author.avatar} name={post.author.name} />
              {post.author.name}
            </span>
            <small className="align-self-center">{post.date}</small>
          </div>

          <img
            src={post.featuredImage}
            alt="Featured"
            className="d-block mb-3"
          />

          <div
            className="mb-3"
            dangerouslySetInnerHTML={{ __html: post.content.rendered }}
          />
        </div>
      </article>

      <SubscriptionForm
        id="blogSubscriptionForm"
        action={process.env.REACT_APP_CUSTOM_FORM_URL}
        responseMessage="Thanks for the subscription."
        captchaType="graboxyCaptcha"
        captchaSiteKey={process.env.REACT_APP_GRABOXY_CAPTCHA_SITE_KEY}
        styles={formStyles}
      />
    </>
  );
};

export default Post;
