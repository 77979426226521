import Section from '../../../../components/Section';
import styles from './Video.module.scss';

const Video = () => (
  <Section
    className={styles.Video}
  >
    <div className="row">
      <div className="col-12 text-center">
        <p>
          AI-backed solutions based on human movement analysis.
          <br />
          Award-winning technology serving over 2 million end users.
        </p>
      </div>
    </div>
    <div className="row">
      <div className="col-md-8 offset-md-2">
        <div className={styles.videoContainer}>
          <iframe
            title="Walking Recognition"
            src="https://player.vimeo.com/video/943589838?h=565c21ca79"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          >
          </iframe>
        </div>
      </div>
    </div>
  </Section>
);

export default Video;
