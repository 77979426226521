import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-scroll';

import PageLayout from '../../layouts/PageLayout';
import Section from '../../components/Section';
import PageTitle from '../../components/PageTitle';
import GetInTouch from '../../components/GetInTouch';
import Seo from '../../components/Seo';

import styles from './Solutions.module.scss';
const Solutions = () => {
  const history = useHistory();

  const navigate = useCallback((to) => {
    if (to.indexOf('http') === 0) {
      const win = window.open(to, '_blank');
      win.focus();
    } else {
      history.push(to);
    }
  }, [history]);

  return (
    <PageLayout>
      <Seo title="Cursor Insight | Solutions" />
      <Section className={styles.Solutions}>
        <PageTitle>Solutions</PageTitle>
        <div className="row mt-lg-5 mb-4">
          <div className="col-lg-6 mb-5 mb-lg-0">
            <div className={styles.SolutionsCard}>
              <div className={styles.Biometrics}>
                <h3>Biometrics</h3>
                <ul>
                  <li>
                    <a
                      href="https://signowise.com"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Biometric e-Signatures
                      <span>SignoWise</span>
                    </a>
                    <a
                      href="https://www.motionscore.ai"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Credit scoring and default prediction
                      <span>MotionScore</span>
                    </a>
                    <a
                      href="https://www.cursorinsight.com/walking-recognition"
                      rel="noreferrer"
                      target="_blank"
                    >
                      CCTV gait recognition
                      <span>Walking Recognition</span>
                    </a>
                    <a
                      href="https://www.cursorinsight.com/sensor-video-analysis"
                      rel="noreferrer"
                      target="_blank"
                    >
                      3D Movement Analysis
                      <span>Fitness Coach and Rowerwatch</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mb-5 mb-lg-0">
            <div className={styles.SolutionsCard}>
              <div className={styles.Cybersecurity}>
                <h3>Cybersecurity</h3>
                <ul>
                  <li>
                    <a
                      href="https://graboxy.com/solutions/continuous-authentication"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Continuous authentication
                      <span>Graboxy</span>
                    </a>
                    <a
                      href="https://graboxy.com/solutions/multi-factor-authentication"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Silent 2FA
                      <span>Graboxy</span>
                    </a>
                    <a
                      href="https://graboxy.com/solutions/device-fingerprinting"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Device Fingerprinting
                      <span>Graboxy</span>
                    </a>
                    <a
                      href="https://graboxy.com/solutions/remote-desktop-detection"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Remote Desktop Detection
                      <span>Graboxy</span>
                    </a>
                    <a
                      href="https://graboxy.com/solutions/captcha-bot-detection"
                      rel="noreferrer"
                      target="_blank"
                    >
                      CAPTCHA & Bot detection
                      <span>Graboxy</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 mb-5 mb-lg-0">
            <div className={styles.SolutionsCard}>
              <div className={styles.Motion}>
                <h3>E-health</h3>
                <ul>
                  <li>
                    <a
                      href="/MotionAnalysisHealth"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Clinical Diagnostics
                      <span>MotionAnalysis.health</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.cursorinsight.com/updrs-assessment-demo"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Parkinson's Assessment
                      <span>MotionAnalysis.health</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mb-5 mb-lg-0">
            <div className={styles.SolutionsCard}>
              <div className={styles.Cheat}>
                <h3>Gaming</h3>
                <ul>
                  <li>
                    <a
                      href="https://anti-cheat.graboxy.com/#/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Aimbot detection
                      <span>Graboxy</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <GetInTouch />
    </PageLayout>
  );
};

export default Solutions;
