import { useLocation } from 'react-router';
import { Link } from 'react-scroll';
import { HashLink } from 'react-router-hash-link';
import PropTypes from 'prop-types';

const ContactButton = ({ className }) => {
  const { pathname } = useLocation();

  if (pathname && pathname.startsWith('/post')) {
    return (
      <HashLink
        to="/#ContactForm"
        className={`nav-link ${className}`}
      >
        Contact
      </HashLink>
    );
  }

  return (
    <Link
      to="ContactForm"
      smooth="true"
      className={`nav-link ${className}`}
    >
      Contact
    </Link>
  );
};

ContactButton.propTypes = {
  className: PropTypes.string,
};

ContactButton.defaultProps = {
  className: undefined,
};

export default ContactButton;
