import Section from '../../../../components/Section';
import styles from './AboutUs.module.scss';

function AboutUs() {
  return (
    <Section title="About Us">
      <div className="container mb-5">
        <div className="row">
          <div className="col-12">
            <h3 className={styles.AboutUs}>
              MotionAnalysis.health is part of the Cursor Insight Group, an
              established, state-of-the-art company in the niche field of
              Human Movement Analysis. MA.h is focusing on healthcare
              applications. CI Group is headquartered in the UK, its
              27-strong team has been perfecting human motion analysis
              technology since 2000. CI meets all relevant ISO, PSD2, SCA
              and GDPR requirements.
            </h3>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default AboutUs;
