import BackToLink from '../../components/BackToLink';
import BlogLayout from './layouts/BlogLayout';
import Post from './components/Post';
import TagsCloud from './components/TagsCloud';
import LatestPosts from './components/LatestPosts';

const PostPage = () => (
  <BlogLayout>
    <div className="container">
      <div className="row my-4">
        <div className="col-12 mb-3">
          <BackToLink to="/blog">Back to the blog</BackToLink>
        </div>

        <div className="col-12 col-lg-8">
          <Post />
        </div>

        <div className="col-12 col-lg-4">
          <TagsCloud />
          <LatestPosts />
        </div>
      </div>
    </div>
  </BlogLayout>
);

export default PostPage;
