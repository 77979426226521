import { Link } from 'react-scroll';

import Section from '../Section';
import styles from './GetInTouch.module.scss';

const GetInTouch = () => (
  <Section>
    <div className="row my-5 px-0 px-md-2">
      <div className="col-12 px-0 px-md-2">
        <div className={styles.GetInTouch}>
          <div className={styles.GetInTouchInner}>
            <h3>
              Tap into our world-leading expertise, become our partner
            </h3>

            <Link to="ContactForm" className="ButtonPrimary">
              Get in touch
            </Link>
          </div>
          <div className={styles.GetInTouchInner}>
            <ul>
              <li>License our AI models to analyze your movement data</li>
              <li>Extract more value from your movement sensor</li>
              <li>Outsource your ML project to save time and money</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </Section>
);

export default GetInTouch;
