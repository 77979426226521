import PropTypes from 'prop-types';

import HexaLink from './HexaLink';
import './Hexagon.scss';
import { useCallback } from 'react';

const Hexagon = ({
  idx, to, title, image, className, onMouse,
}) => {
  const classes = ["Hexagon", className].join(' ');

  const onOver = useCallback(() => {
    onMouse(idx);
  }, [idx, onMouse]);

  const onOut = useCallback(() => {
    onMouse(0);
  }, [onMouse]);

  return (
    <HexaLink
      to={to}
      className={classes}
      onOver={onOver}
      onOut={onOut}
    >
      <div className="hexa-border">
        <div className="hexa-bg">
          <div className="hexa-shadow">
            <div className="hexa-selector">
              <h3 dangerouslySetInnerHTML={{ __html: title }} />
              {image && <img src={image} alt={title} />}
            </div>
          </div>
        </div>
      </div>
    </HexaLink>
  );
};

Hexagon.propTypes = {
  idx: PropTypes.number.isRequired,
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  onMouse: PropTypes.func.isRequired,
};

Hexagon.defaultProps = {
  className: undefined,
  image: undefined,
};

export default Hexagon;
