import '@popperjs/core';
import 'bootstrap';
import TagManager from 'react-gtm-module';

import { StrictMode } from 'react';
import { render } from 'react-dom';

import './styles/index.scss';
import App from './App';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_CONTAINER_ID,
};

TagManager.initialize(tagManagerArgs);

render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root'),
);
