import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';

import styles from './Markdown.module.scss';

const Markdown = ({ children }) => (
  <ReactMarkdown className={styles.Markdown}>
    {children}
  </ReactMarkdown>
);

Markdown.propTypes = {
  children: PropTypes.string,
};

Markdown.defaultProps = {
  children: undefined,
};

export default Markdown;
