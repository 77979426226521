/* eslint-disable jsx-a11y/img-redundant-alt */
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link } from 'react-scroll';
import Section from '../../../../components/Section';

import './usecasetabs.scss';

import hospital from './hospital.svg';
import run from './run.svg';
import brain from './brain.svg';
import todo from './todo.svg';
import brain2 from './brain.png';

function UseCaseTabs() {
  return (
    <>
      <Section
        title="Let us turn your motion data into quantifiable digital
        biomarkers"
        className="pb-5 mb-5"
        maxWidth={1080}
      >
        <Tabs defaultActiveKey="one">
          <Tab eventKey="one" title="Clinical Trials">
            <img src={hospital} alt="image" />
            <p>
              Our computer vision-based solution ensures precise movement
              measurements without the use of cumbersome sensors. Uncover and
              track the effects of pharmaceuticals through objective,
              quantitative and comparable data.
            </p>
          </Tab>
          <Tab eventKey="Two" title="Sports Medicine">
            <img src={run} alt="image" />
            <p>
              We discover poor technique or injuries by identifying the
              position and movement of individual body parts by analyzing video
              footage and without the need of wearable sensors.
            </p>
          </Tab>
          <Tab eventKey="Three" title="Cognitive Screening">
            <img src={brain} alt="image" />
            <p>
              We analyze cursor or mobile sensor data while users complete an
              on-screen cognitive test.  Our motion analysis combined with the
              traditional tests lead to more accurate screening.
            </p>
          </Tab>
          <Tab eventKey="Four" title="Regular Check-Ups">
            <img src={todo} alt="image" />
            <p>
              Automate standard tests to gain more objective and granular
              information about your patients. Not only will you save time, but
              you will have deeper insights into the progression of your
              patients' conditions.
            </p>
          </Tab>
        </Tabs>
      </Section>
      <Section
        className="pb-5 mb-5"
        maxWidth={1080}
      >
        <div className="OnlyDesktop row box py-5">
          <div
            className="col-lg-6 text-center d-flex flex-column
            justify-content-center align-items-center px-5"
          >
            <h2 className="mb-4">Clinical Partners</h2>
            <p className="mb-4">
              We are able to collect and analyze medical data in cooperation
              with our mental health, neurology and neurosurgery clinical
              partner.
            </p>
            <Link to="ContactForm" className="ButtonPrimary">
              Contact
            </Link>
          </div>
          <div
            className="col-lg-6 text-center d-flex flex-column
            justify-content-center align-items-center px-5"
          >
            <img src={brain2} alt="brain" width={220} className="mb-4" />
            <h3>Hungarian National Institute of Clinical Neurosciences</h3>
          </div>
        </div>
        <div className="OnlyMobile box py-5">
          <div
            className="text-center justify-content-center
            align-items-center px-5"
          >
            <h3>Hungarian National Institute of Clinical Neurosciences</h3>
            <img src={brain2} alt="brain" width={220} className="mb-4" />
          </div>
          <div
            className="text-center justify-content-center
            align-items-center px-5 pt-5"
          >
            <h2 className="mb-4">Clinical Partners</h2>
            <p className="mb-4">
              We are able to collect and analyze medical data in cooperation
              with our mental health, neurology and neurosurgery clinical
              partner.
            </p>
            <Link to="ContactForm" className="ButtonPrimary">
              Contact
            </Link>
          </div>
        </div>
      </Section>
    </>
  );
}

export default UseCaseTabs;
