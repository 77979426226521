import PropTypes from 'prop-types';

import ContactForm from '../ContactForm';
import FaIcon from '../FaIcon';
import FooterLink from './FooterLink';
import styles from './Footer.module.scss';

import logo from './logo.svg';
import twitter from '../../images/twitter-logo.png';
import iso from '../../images/ISO27001certified.svg';

const currentYear = new Date().getFullYear();

const Footer = ({ withContact }) => (
  <footer>
    {withContact && <ContactForm />}
    <div className={styles.Footer}>
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-5 p-4 p-lg-0">
            <div className="mb-4">
              <img src={logo} className={styles.FooterLogo} />
            </div>
            <div className={styles.Info}>
              <span>
                &copy; {currentYear}, Cursor Insight Ltd
              </span>
              {' '}
              |
              <FooterLink to="/document/cookie-policy">
                Cookie Policy
              </FooterLink>
              |
              <FooterLink to="/document/privacy-policy">
                Privacy Policy
              </FooterLink>
            </div>
            <div className="mb-4">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://twitter.com/cursorinsight"
                className={`mx-1 mx-lg-0 me-lg-2 ${styles.Twitter}`}
              >
                <img src={twitter} alt="Twitter" />
              </a>

              <FaIcon
                id="fa-linkedin"
                href="https://www.linkedin.com/company/cursor-insight"
              />

              <FaIcon
                id="fa-facebook"
                href="https://www.facebook.com/cursorinsight"
              />

              <FaIcon
                id="fa-github"
                href="https://github.com/cursorinsight"
              />

              <FaIcon
                id="fa-youtube"
                href="https://www.youtube.com/@cursorinsight7572"
              />
            </div>
            <div className={styles.Offices}>
              <span>
                London | United Kingdom
              </span>
              <span>
                Budapest | Hungary
              </span>
              <img src={iso} className="mt-4" />
            </div>
          </div>

          <div className="col-lg-7">
            <div className={styles.MenuWrapper}>
              <div className={styles.MenuItem}>
                <h3>
                  <a href="/solutions">Solutions</a>
                </h3>
                <ul>
                  <li>
                    <span>
                      Cybersecurity
                    </span>
                    <a
                      href="https://graboxy.com/solutions/continuous-authentication"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Continuous Authentication
                    </a>
                    <a
                      href="https://graboxy.com/solutions/multi-factor-authentication"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Silent 2FA
                    </a>
                    <a
                      href="https://graboxy.com/solutions/device-fingerprinting"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Device Fingerprinting
                    </a>
                    <a
                      href="https://graboxy.com/solutions/remote-desktop-detection"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Remote Desktop Detection
                    </a>
                    <a
                      href="https://graboxy.com/solutions/captcha-bot-detection"
                      rel="noreferrer"
                      target="_blank"
                    >
                      CAPTCHA & Bot Detection
                    </a>
                  </li>
                  <li>
                    <span>
                      e-Health
                    </span>
                    <a
                      href="/MotionAnalysisHealth"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Clinical Diagnostics
                    </a>
                    <a
                      href="https://www.cursorinsight.com/updrs-assessment-demo"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Parkinson's Assessment
                    </a>
                  </li>
                  <li>
                    <span>
                      Biometrics
                    </span>
                    <a
                      href="https://signowise.com"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Biometrics e-Signatures
                    </a>
                    <a
                      href="https://www.motionscore.ai"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Credit Scoring & Default Prediction
                    </a>
                    <a
                      href="https://www.cursorinsight.com/walking-recognition"
                      rel="noreferrer"
                      target="_blank"
                    >
                      CCTV Gait Recognition
                    </a>
                    <a
                      href="https://www.cursorinsight.com/sensor-video-analysis"
                      rel="noreferrer"
                      target="_blank"
                    >
                      3D Movement Analysis
                    </a>
                  </li>
                  <li>
                    <span>
                      Gaming
                    </span>
                    <a
                      href="https://anti-cheat.graboxy.com/#/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Aimbot Detection
                    </a>
                  </li>
                </ul>
              </div>

              <div className={styles.MenuItem}>
                <h3>
                  <a href="/">Use Cases</a>
                </h3>
                <ul>
                  <li>
                    <a href="/banking-solutions">
                      Banking
                    </a>
                    <a href="/healthcare">
                      Healthcare
                    </a>
                    <a href="/government">
                      Government
                    </a>
                    <a href="/law-enforcement">
                      Law Enforcement
                    </a>
                    <a href="/cybersecurity">
                      Cybersecurity
                    </a>
                    <a href="/gaming">
                      Gaming
                    </a>
                  </li>
                </ul>
                <h3>
                  <a href="/">Resources</a>
                </h3>
                <ul>
                  <li>
                    <a href="/resources#use-cases">
                      Case Studies
                    </a>
                    <a href="/resources#blog">
                      Blog
                    </a>
                    <a href="/resources#whitepapers">
                      Whitepaper
                    </a>
                    <a href="/resources#scientific-papers">
                      Scientific Papers
                    </a>
                    <a href="/resources#posters">
                      Posters
                    </a>
                  </li>
                </ul>
              </div>

              <div className={styles.MenuItem}>
                <h3>
                  <a href="/">Technology</a>
                </h3>
                <ul>
                  <li>
                    <a href="/technology">
                      What’s under the hood
                    </a>
                  </li>
                </ul>
                <h3>
                  <a href="/">Contact</a>
                </h3>
                <ul>
                  <li>
                    <a href="mailto:info@cursorinsight.com">
                      Contact Cursor Insight
                    </a>
                    <a href="mailto:support@cursorinsight.com">
                      Contact Support
                    </a>
                    <a href="mailto:investing@cursorinsight.com">
                      For Investors
                    </a>
                    <a href="mailto:marketing@cursorinsight.com">
                      Marketing
                    </a>
                    <a
                      href="https://calendly.com/tamas-cursor-insight/consultation"
                      target="_blank"
                    >
                      Request Pricing
                    </a>
                    <a
                      href="https://calendly.com/tamas-cursor-insight/consultation"
                      target="_blank"
                    >
                      Request Demo
                    </a>
                  </li>
                </ul>
                <h3>
                  <a href="/">Company</a>
                </h3>
                <ul>
                  <li>
                    <a href="/our-team">
                      Our Team
                    </a>
                    <a href="/our-team">
                      Founders
                    </a>
                    <a href="/our-team">
                      Career
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

Footer.propTypes = {
  withContact: PropTypes.bool,
};

Footer.defaultProps = {
  withContact: true,
};

export default Footer;
