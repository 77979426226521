import { Link } from 'react-router-dom';

import Section from '../../../../components/Section';
import Hexagons from './Hexagons';

const Technology = () => (
  <Section
    title="One technology, boundless applications"
    className="py-5 text-center mb-5 hexagonsMain"
  >
    <div className="row my-5">
      <div className="col-12 my-5">
        <Hexagons />
      </div>

      <div className="col-12">
        <Link to="/use-cases" className="ButtonPrimary">
          More about our solutions
        </Link>
      </div>
    </div>
  </Section>
);

export default Technology;
