import React from 'react'

import PageLayout from '../../layouts/PageLayout';
import Section from '../../components/Section';
import Seo from '../../components/Seo';

import styles from './ContionusAuthentication.module.scss';

import WhoWeAre from '../IndexPage/sections/WhoWeAre';
import Testimonials from '../IndexPage/sections/Testimonials';

import banking from '../../images/banking.png';

import award0 from './award0.svg';
import award6 from './award6.png';
import sentinel from './inverse-logo-graboxy-sentinel.svg'
import piktogram from './piktogram.png'

const ContionusAuthentication = () => {
  return (
    <PageLayout>
      <Seo title="Cursor Insight | Online User Authentication Banking" />
      <div className={styles.ContionusAuthentication}>
        <Section
          title="Continuous Online User Authentication in Banking"
        >
          <div className="container">
            <div className="row mb-5 pb-5">
              <div className="col-12 text-center">
                <div className={styles.Intro}>
                  <div className={styles.Card}>
                    <img src={banking} alt="" width={120} />
                  </div>
                  <p><strong>Customer:</strong> Large Bank in the CEE Region</p>
                  <p>
                    <strong>Goal:</strong> Decrease the number of fraudulent
                    online banking transactions
                    <br />
                    and reduce the cost of transaction authorization
                  </p>
                  <p>
                    <strong>Solution:</strong>
                    {' '}
                    <a href="https://sentinel.graboxy.com" target="_blank">
                      Graboxy Sentinel Online
                    </a>
                  </p>
                  <a
                    href="https://calendly.com/tamas-cursor-insight/consultation
                    "
                    target="_blank"
                    className="ButtonPrimary mt-3"
                  >
                    Schedule a call
                  </a>
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-lg-6">
                <div className={styles.Element}>
                  <h2>Challenge</h2>
                  <p>
                    By the first half of 2023, the country's annual incidence
                    of credit transfer fraud exceeded 22 million USD. In 2019,
                    this amount was below 3 million USD; by the latter part of
                    2020, it only rose to over 4.5 million USD. Additionally,
                    the cost of sending OTPs via SMS has been steadily
                    increasing.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className={styles.Element}>
                  <img src={piktogram} />
                </div>
              </div>
            </div>
            <div className={`row mb-5 ${styles.Reverse}`}>
              <div className="col-lg-6">
                <div className={styles.Element}>
                  <iframe
                    src="https://player.vimeo.com/video/694347500?h=b41ff280fe"
                    width="540"
                    height="315"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen
                  >
                  </iframe>
                </div>
              </div>
              <div className="col-lg-6">
                <div className={styles.Element}>
                  <h2>Solution</h2>
                  <p>
                    <a href="https://sentinel.graboxy.com" target="_blank">
                      Graboxy Sentinel
                    </a>
                    {' '}
                    Online uses movement biometrics to
                    continuously authenticate users. It gathers cursor movement
                    data from the online banking platform through the browser
                    to create identity profiles. If the real-time cursor
                    movement analysis shows a divergence from the user's
                    biometric profile, Graboxy Sentinel flags the suspicious
                    user sessions. Flagged users can be locked out or
                    re-verified.
                  </p>
                </div>
              </div>
            </div>
            <div className={`row mb-md-5 text-center ${styles.Ordered}`}>
              <div
                className="col-lg-4 d-flex justify-content-center
                align-items-center"
              >
                <div>
                  <img src={award0} width={150} className={styles.Image}/>
                </div>
              </div>
              <div
                className="col-lg-4 d-flex justify-content-center
                align-items-center"
              >
                <div>
                  <a href="https://sentinel.graboxy.com" target="_blank">
                    <img src={sentinel} width={400} className={styles.Image}/>
                  </a>
                </div>
              </div>
              <div
                className="col-lg-4 d-flex justify-content-center
                align-items-center"
              >
                <div>
                  <img src={award6} width={220} className={styles.Image}/>
                </div>
              </div>
            </div>
            <div className="row mb-5 text-center">
              <div className="col-12">
                <a
                  href="https://calendly.com/tamas-cursor-insight/consultation"
                  target="_blank"
                  className="ButtonPrimary mt-3"
                >
                  Schedule a call
                </a>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-12">
                <h2 className={styles.Title}>How it works</h2>
                <div className={styles.Steps}>
                  <div className={styles.Step}>
                    <h5>Step 1</h5>
                    <p>
                      The user logs in to the banking account and carries
                      out the usual actions by moving the cursor.
                    </p>
                  </div>
                  <div className={styles.Step}>
                    <h5>Step 2</h5>
                    <p>
                      Graboxy Sentinel Online analyzes the cursor dynamics of
                      the user in real time.
                    </p>
                  </div>
                  <div className={styles.Step}>
                    <h5>Step 3</h5>
                    <p>
                      By the time the user initiates a transaction, Graboxy
                      Sentinel authenticates the user.
                    </p>
                  </div>
                  <div className={styles.Step}>
                    <p>
                      <span className={styles.Green}>Authorized user</span>
                      <br />
                      Silent 2FA completed
                    </p>
                    <hr />
                    <p>
                      <span className={styles.Red}>Unauthorized user</span>
                      <br />
                      Request for traditional 2FA
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-12">
                <div className={styles.Results}>
                  <h2>Results</h2>
                  <div className="d-md-flex justify-content-center my-5">
                    <div className={styles.ResultItem}>
                      <p>
                        Cost saving on
                        <br />
                        OTPs via SMS:
                      </p>
                      <h3>-85%</h3>
                    </div>
                    <div className={styles.ResultItem}>
                      <p>Fraud detection accuracy*:</p>
                      <h3>97%</h3>
                      <span>
                        * Where sufficient training data was available.
                      </span>
                    </div>
                    <div className={styles.ResultItem}>
                      <p>Authenticated user sessions daily:</p>
                      <h3>100K+</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <Testimonials />
        <WhoWeAre />
      </div>
    </PageLayout>
  );
}

export default ContionusAuthentication;
