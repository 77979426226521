import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const FooterLink = ({ to, children }) => (
  <Link to={to} className="px-2" target="_blank">
    {children}
  </Link>
);

FooterLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};

export default FooterLink;
