import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-scroll';

import PageLayout from '../../layouts/PageLayout';
import Section from '../../components/Section';
import PageTitle from '../../components/PageTitle';
import GetInTouch from '../../components/GetInTouch';
import Seo from '../../components/Seo';

import styles from './UseCases.module.scss';

import banking from '../../images/banking.png';
import gaming from '../../images/gaming.png';
import government from '../../images/government.png';
import healthcare from '../../images/healthcare.png';
import law from '../../images/law.png';
import security from '../../images/security.png';

import graboxySentinel from '../../images/inverse-logo-graboxy-sentinel.svg';
import graboxyCaptcha from '../../images/inverse-logo-graboxy-captcha.svg';
import graboxy2fa from '../../images/inverse-logo-graboxy-2fa.svg';
import graboxyAnticheat from '../../images/inverse-logo-graboxy-anticheat.svg';
import motionAnalysis from '../../images/inverse-logo-motionanalysis.png';
import signoWise from '../../images/inverse-logo-signo-wise.svg';
import cursorInsight from '../../images/inverse-logo-cursor-insight.svg';
import motionScore from '../../images/motionscore.svg';

const UseCasesPage = () => {
  const history = useHistory();

  const navigate = useCallback((to) => {
    if (to.indexOf('http') === 0) {
      const win = window.open(to, '_blank');
      win.focus();
    } else {
      history.push(to);
    }
  }, [history]);

  return (
    <PageLayout>
      <Seo title="Cursor Insight | Use Cases" />
      <Section className={styles.UseCases}>
        <PageTitle>Use cases</PageTitle>
        <div className="row">
          <div className="col-12">
            <p className="text-center mt-2 mb-5" style={{ color: 'white', fontSize: '20px' }}>
              Explore the wide range of applications of Cursor Insight’s
              award-winning movement analysis technology.
              <br/>
              {' '}
              <Link className="mainlink" to="ContactForm" spy smooth>
                Get in touch
              </Link>
              {' '}
              to arrange a demo.
            </p>
          </div>
        </div>
        <div className={styles.usecaseIntro}>
          <div className="row">
            <div className="col-md-4">
              <a href="/banking-solutions">
                <div className={styles.Image}>
                  <img src={banking} alt="" />
                </div>
                <h5>Banking</h5>
              </a>
            </div>
            <div className="col-md-4">
              <a href="/healthcare">
                <div className={styles.Image}>
                  <img src={healthcare} alt="" />
                </div>
                <h5>Healthcare</h5>
              </a>
            </div>
            <div className="col-md-4">
              <a href="/government">
                <div className={styles.Image}>
                  <img src={government} alt="" />
                </div>
                <h5>Government</h5>
              </a>
            </div>
            <div className="col-md-4">
              <a href="/cybersecurity">
                <div className={styles.Image}>
                  <img src={security} alt="" />
                </div>
                <h5>Cybersecurity</h5>
              </a>
            </div>
            <div className="col-md-4">
              <a href="/gaming">
                <div className={styles.Image}>
                  <img src={gaming} alt="" />
                </div>
                <h5>Gaming</h5>
              </a>
            </div>
            <div className="col-md-4">
              <a href="/law-enforcement">
                <div className={styles.Image}>
                  <img src={law} alt="" />
                </div>
                <h5>Law enforcement</h5>
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <h5>CONTINUOUS AUTHENTICATION</h5>
            <div>
              <img src={graboxySentinel} alt="Graboxy Sentinel" />
              <p>
                Protect your organization and service from identity fraud
                and account takeover with continuous biometric
                authentication. Our AI continuously monitors cursor movement
                characteristics and reports suspicious behavior. Use the
                risk score to automatically trigger MFA or lock out
                unauthorized users.
              </p>
              <span>
                <a
                  href="/casestudy/online-user-authentication-banking"
                  target="_blank"
                  className="mainlink"
                >
                  Case study
                </a>
                <a
                  href="https://sentinel.graboxy.com"
                  target="_blank"
                  rel="noreferrer"
                  className="mainlink"
                >
                  More
                </a>
              </span>
            </div>
          </div>

          <div className="col-lg-6">
            <h5>HUMAN TRAFFIC VERIFICATION</h5>
            <div>
              <img src={graboxyCaptcha} alt="Graboxy Captcha" />
              <p>
                Replace fire hydrants and traffic lights with a Captcha that
                uses movement analysis to verify humans. Users just need to
                solve a simple maze with the cursor to pass the challenge. The
                maze can be customized with your brand elements. Initiate user
                verification or block the session based on our evaluation.
                Frustrate bots, not your users.
              </p>
              <span>
                <a
                  href="https://captcha.graboxy.com"
                  target="_blank"
                  rel="noreferrer"
                  className="mainlink"
                >
                  More
                </a>
              </span>
            </div>
          </div>

          <div className="col-lg-6">
            <h5>MULTIFACTOR AUTHENTICATION</h5>
            <div>
              <img src={graboxy2fa} alt="Graboxy 2FA" />
              <p>
                Authenticate users invisibly in the background of the user
                session with Graboxy Sentinel or use an optional 5-second
                movement challenge with Graboxy 2FA. No need to send costly SMS
                codes for the users to switch between devices and apps. We
                turn cursor movements into biometric profiles that cannot be
                stolen or imitated. Our solution is compliant with PSD2 SCA and
                offers OAuth2 integration.
              </p>
              <span>
                <a
                  href="/casestudy/online-user-authentication-banking"
                  target="_blank"
                  className="mainlink"
                >
                  Case study
                </a>
                <a
                  href="https://2fa.graboxy.com"
                  target="_blank"
                  rel="noreferrer"
                  className="mainlink"
                >
                  More
                </a>
              </span>
            </div>
          </div>

          <div className="col-lg-6">
            <h5>AIMBOT DETECTION</h5>
            <div>
              <img src={graboxyAnticheat} alt="Graboxy Anti Cheat" />
              <p>
                Protect the reputation of your e-sport or gaming platform
                from cheaters. We flag Counterstrike GO players cheating
                with aimbots through server-side game data analysis. Get
                results within minutes after each round through a simple
                API call. By learning players’ unique movement patterns,
                we can spot banned cheaters with new accounts.
              </p>
              <span>
                <a
                  href="https://anticheat.graboxy.com"
                  target="_blank"
                  rel="noreferrer"
                  className="mainlink"
                >
                  More
                </a>
              </span>
            </div>
          </div>

          <div className="col-lg-6">
            <h5>CLINICAL DIAGNOSTICS</h5>
            <div>
              <img src={motionAnalysis} alt="Motion Analysis" width={380}/>
              <p>
                Eliminate subjective clinical diagnostics. Automate
                movement-based neurological tests, such as UPDRS, with our
                machine learning powered analysis. Get reliable, easily
                comparable digital biomarkers extracted from patient videos,
                without using motion sensors or physical markers. Enhance
                clinical trials, cognitive screening or remote patient
                monitoring.
              </p>
              <span>
                <a
                  href="/casestudy/detecting-dementia"
                  target="_blank"
                  rel="noreferrer"
                  className="mainlink"
                >
                  Case study
                </a>
                <a
                  href="/MotionAnalysisHealth"
                  target="_blank"
                  rel="noreferrer"
                  className="mainlink"
                >
                  More
                </a>
              </span>
            </div>
          </div>

          <div className="col-lg-6">
            <h5>BIOMETRIC E-SIGNATURES</h5>
            <div>
              <img src={signoWise} alt="SignoWise" />
              <p>
                Go paperless while improving customer confidence with our
                biometric e-signatures. There is no need for signing
                certificates or tokens, users simply sign a touchpad to
                provide legally binding consent to a document. Our system
                automatically verifies the genuineness of a signature by
                analysing its unique dynamic characteristics.
              </p>
              <span>
                <a
                  href="/casestudy/biometric-esignature-banking"
                  target="_blank"
                  rel="noreferrer"
                  className="mainlink"
                >
                  Case study
                </a>
                <a
                  href="https://signowise.com"
                  target="_blank"
                  rel="noreferrer"
                  className="mainlink"
                >
                  More
                </a>
              </span>
            </div>
          </div>

          <div className="col-lg-6">
            <h5>WALKING RECOGNITION</h5>
            <div>
              <img src={cursorInsight} alt="Cursor Insight" />
              <p>
                Identify unauthorized persons entering premises or using
                someone else’s RFID card by deploying our technology that
                recognises people based on how they walk. No need to
                collect or process sensitive biometric data or to install
                special hardware. We only analyse CCTV video and send a
                real-time notification upon the suspicion of an
                unauthorized entry or even spotting someone in distress.
              </p>
              <span>
                <a
                  href="/walking-recognition"
                  target="_blank"
                  className="mainlink"
                >
                  More
                </a>
              </span>
            </div>
          </div>

          <div className="col-lg-6">
            <h5>3D Movement Analysis</h5>
            <div>
              <img src={cursorInsight} alt="Cursor Insight" />
              <p>
                Hand gestures, eye and body movements often reveal more
                information than spoken words. Our AI classifies movement
                samples to help train athletes, prevent sport injuries and
                detect dangerous vehicle movements leading to accidents.
                We can process movement data from videos and all kinds of
                movement sensors, including the ones built in mobile phones.
              </p>
              <span>
                <a
                  href="/casestudy/drone-detection"
                  target="_blank"
                  className="mainlink"
                >
                  Case study
                </a>
                <a
                  href="/sensor-video-analysis"
                  target="_blank"
                  className="mainlink"
                >
                  More
                </a>
              </span>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.usecaseCard}>
            <h5>Credit scoring and default prediction</h5>
            <div>
              <img src={motionScore} alt="Motion Score" width={350} />
              <p>
                MotionScore is an innovative platform designed to enhance
                credit scoring and default prediction by analyzing signature
                patterns, motion data, and user interaction with mobile devices.
                Our patent-pending technology equips our cutting-edge
                AI algorithms to scrutinize this multi-faceted data, identify
                hidden patterns, and generate accurate credit scores that
                enable financial institutions to effectively manage risk based
                on anonymized data.
              </p>
              <span>
                <a
                  href="https://www.motionscore.ai/"
                  target="_blank"
                  rel="noreferrer"
                  className="mainlink"
                >
                  More
                </a>
              </span>
            </div>
          </div>
        </div>
      </Section>
      <GetInTouch />
    </PageLayout>
  );
};

export default UseCasesPage;
