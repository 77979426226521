import React from 'react'

import PageLayout from '../../layouts/PageLayout';
import Section from '../../components/Section';
import Seo from '../../components/Seo';

import styles from './DroneDetection.module.scss';

import WhoWeAre from '../IndexPage/sections/WhoWeAre';
import Testimonials from '../IndexPage/sections/Testimonials';

import drone from './drone.png';
import img1 from './img1.png';
import img2 from './img2.png';

const DroneDetection = () => {
  return (
    <PageLayout>
      <Seo title="Cursor Insight | Drone Detection Using Radar Data" />
      <div className={styles.DroneDetection}>
        <Section
          title="Drone Detection Using Radar Data"
        >
          <div className="container">
            <div className="row mb-5 pb-5">
              <div className="col-12 text-center">
                <div className={styles.Intro}>
                  <div className={styles.Card}>
                    <img src={drone} alt="drone" width={160} />
                  </div>
                  <p><strong>Partner:</strong> Air traffic control provider</p>
                  <p>
                    <strong>Goal:</strong>
                    {' '}
                    Identify commercial drones and differentiate them from birds
                    <br />
                    by using airport radar infrastructure
                  </p>
                  <p>
                    <strong>Solution:</strong> Drone Detection by Cursor Insight
                  </p>
                  <a
                    href="https://calendly.com/tamas-cursor-insight/consultation
                    "
                    target="_blank"
                    className="ButtonPrimary mt-3"
                  >
                    Schedule a call
                  </a>
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-lg-6">
                <div className={styles.Element}>
                  <h2>Challenge</h2>
                  <p>
                    Drones present a danger and threat to privacy in restricted
                    airspaces such as airports, military infrastructure, or
                    prisons. This project aimed to determine the feasibility of
                    detecting and distinguishing drones from birds and other
                    aircraft in the airspace around the airport, using radar
                    data. The challenges lie in developing effective target
                    software that can provide air traffic control with
                    meaningful information on small flying objects, potentially
                    mitigating threats to flights near airports without
                    installing extra radars.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className={styles.Element}>
                  <img src={img1} alt="img1" />
                </div>
              </div>
            </div>
            <div className={`row mb-5 ${styles.Reverse}`}>
              <div className="col-lg-6">
                <div className={styles.Element}>
                  <img src={img2} alt="img2" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className={styles.Element}>
                  <h2>Solution</h2>
                  <p>
                    We developed a solution to analyze the motion data in
                    images captured by radar and video surveillance systems
                    and automatically identify drones in real time based on
                    their unique movement patterns. This way, drones can be
                    spotted and differentiated from other detectable flying
                    objects, such as airplanes or harmless birds. Our algorithm
                    can process a 2-dimensional projection of 3-dimensional
                    movement data, such as a 2-D video footage of an object
                    flying in a 3-D space.
                  </p>
                </div>
              </div>
            </div>
            <div className="text-center">
              <a
                href="https://calendly.com/tamas-cursor-insight/consultation
                    "
                target="_blank"
                className="ButtonPrimary mt-3"
              >
                Schedule a call
              </a>
            </div>
          </div>
        </Section>
        <Testimonials />
        <WhoWeAre />
      </div>
    </PageLayout>
  );
}

export default DroneDetection;
