import Slider from 'react-slick';

import AWARDS from './Awards.data';
import Award from './Award';
import './AwardSlider.scss';

const SETTINGS = {
  speed: 9000,
  autoplay: true,
  autoplaySpeed: 0,
  cssEase: 'linear',
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  arrows: false
};

const Awards = () => {
  const renderedAwards = AWARDS.map((award, i) => (
    <Award key={i} {...award} />
  ));

  return (
    <div className="row mt-3 mb-5">
      <div className="col-12 text-center pb-5">
        <Slider {...SETTINGS} className="AwardSlider">
          {renderedAwards}
        </Slider>
      </div>
    </div>
  );
};

export default Awards;
