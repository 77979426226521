import React from 'react';
import PropTypes from 'prop-types';

import profilePlaceholder from './images/testimonials-profile-placeholder.png';
import styles from './Testimonial.module.scss';

const Testimonial = ({
  image, name, description, quote,
}) => (
  <div className={styles.Testimonial}>
    <div className={styles.Image}>
      <img src={image} alt={`Profile of ${name}`} />
    </div>

    <div className={styles.Text}>
      <h5 className="m-0">{name}</h5>

      <h6>
        {description.map((line, i) => (
          <font key={i}>{line}<br /></font>
        ))}
      </h6>

      <span />

      <p>{quote}</p>
    </div>
  </div>
);

Testimonial.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  description: PropTypes.arrayOf(
    PropTypes.string
  ).isRequired,
  quote: PropTypes.string.isRequired,
};

Testimonial.defaultProps = {
  image: profilePlaceholder,
};

export default Testimonial;
