import Section from '../Section';
import styles from './Hero.module.scss';
import './Hero.scss';
import ai from '../../images/ai.png';

const Hero = () => (
  <Section className={styles.Hero}>
    <div className="row">
      <div className="col-lg-5">
        <div className="section-process">
          <div className="section-container">
            <div className="process-steps-container container-medium with-padding">
              <div className="process-step-container process-step-1">
                <div className="process-step-title-container">
                  <h1 className="process-step-title">Putting AI</h1>
                  <div className="process-step-title-overlay">Putting AI</div>
                </div>
              </div>
              <div className="process-step-container process-step-2">
                <div className="process-step-title-container">
                  <h1 className="process-step-title">in Motion</h1>
                  <div className="process-step-title-overlay">in Motion</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-7">
        <div className={styles.Spinner}>
          <div className={styles.SpinnerInner}>
            <h3>Building a<br/>more complete AI<br/>for the world</h3>
          </div>
          <div className={styles.SpinnerInner}>
            <img src={ai} alt="" />
          </div>
        </div>
      </div>
    </div>
  </Section>
);

export default Hero;
