import { Link } from 'react-scroll';
import Section from '../../../../components/Section';

import Publication1 from '../../images/Publication1.png';

function Tables() {
  return (
    <Section className="pt-5">
      <div className="container">
        <div className="row">
          <div className="col-12 mb-5">
            <div className="business-card">
              <div>
                <h3>Parkinson’s and Alzheimer’s Assessment</h3>
                <p>
                  We automated standard tests, such as UPDRS to gain more
                  objective and granular information about patients. Not
                  only will you save time, but you will have deeper
                  insights into the progression of your patients'
                  conditions.
                </p>
                <ul>
                  <li>
                    No need for special equipment or wearable markers
                  </li>
                  <li>
                    Objective, quantitative and comparable data
                  </li>
                  <li>
                    Can be used easily at home
                  </li>
                </ul>
                <a
                  href="https://www.cursorinsight.com/updrs-assessment-demo"
                  rel="noreferrer"
                  target="_blank"
                  className="ButtonPrimary motionAnalysis"
                >
                  Click for the demo
                </a>
              </div>
              <div>
                <div className="iframe-container">
                  <iframe
                    src="https://player.vimeo.com/video/822017736?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    title="Cursor Insight"
                    frameborder="0"
                    allow="accelerometer; picture-in-picture; web-share"
                    allowfullscreen
                  >
                  </iframe>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mb-5">
            <div className="business-card">
              <div>
                <h3>Dementia Detection</h3>
                <p>
                  We achieved 79.8% accuracy in a study predicting Mild
                  Cognitive Impairment (MCI) purely based on cursor movement
                  analysis. Our research paper presenting our results is
                  published in the prestigious ‘Informatics in Medicine
                  Unlocked’ journal
                </p>
                <a
                  href="https://www.sciencedirect.com/science/article/pii/S235291482200257X"
                  rel="noreferrer"
                  target="_blank"
                  className="ButtonPrimary motionAnalysis"
                >
                  Read our paper
                </a>
              </div>
              <div>
                <img src={Publication1} alt="Publication" />
              </div>
            </div>
          </div>
          <div className="col-12 mb-5">
            <div className="business-card">
              <div>
                <h3>3D Body Modeling</h3>
                <p>
                  See how we model the human body in 3D with only 2 ordinary
                  cameras and then recognize people by analyzing their body
                  movement.
                </p>
                <Link to="ContactForm" className="ButtonPrimary motionAnalysis">
                  Contact
                </Link>
              </div>
              <div>
                <div className="iframe-container">
                  <iframe
                    src="https://player.vimeo.com/video/1017903392?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    title="Cursor Insight"
                    frameborder="0"
                    allow="accelerometer; picture-in-picture; web-share"
                    allowfullscreen
                  >
                  </iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default Tables;
