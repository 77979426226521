import React from 'react';
import { useParams } from 'react-router';

import { ContainerSpinner } from '../../../components/Spinner';
import usePosts from '../hooks/usePosts';
import PostTile from './PostTile';

const LatestPosts = () => {
  const { postId } = useParams();
  const { isLoading, posts } = usePosts({ limit: 5 });

  const renderedPosts = posts
    // eslint-disable-next-line
    .filter((post) => post.id != postId)
    .map((post) => (<PostTile key={post.id} post={post} />));

  renderedPosts.length = 4;

  return (
    <div className="row mt-5 mt-lg-0">
      <div className="col-12 mb-2">
        <h3 style={{ color: '#ffffff' }}>Latest Posts</h3>
      </div>

      {isLoading
        ? <ContainerSpinner />
        : <>{renderedPosts}</>
      }
    </div>
  );
};

export default LatestPosts;
