import benceKisfalvi from './images/testimonials-profile-bence-kisfalvi.png';
import adamFeldmannPhf from './images/testimonials-profile-adam-feldmann-phd.jpg';
import imranMalik from './images/testimonials-profile-imran-malik.jpg';
import peterErdosi from './images/testimonials-profile-peter-erdosi.jpg';
import moritzGrumbach from './images/testimonials-profile-moritz-grumbach.jpg';
import faridSingh from './images/testimonials-profile-farid-singh.jpg';

const TESTIMONIALS = [
  {
    image: benceKisfalvi,
    name: 'Bence Kisfalvi',
    description: [
      'Business Development Director',
      'OTP Bank'
    ],
    quote: [
      'We needed a faster, paperless contracting process to save time for both',
      'our employees and clients. Cursor Insight efficiently delivered us the',
      'critical banking solution that is in use at all of our 400 branches today.',
    ].join(' '),
  },
  {
    image: adamFeldmannPhf,
    name: 'Adam Feldmann PhD',
    description: [
      'Head of Big Data Research Group',
      'University of Pecs'
    ],
    quote: [
      'Cursor Insight’s professional and approachable team truly understands',
      'the scientific demands of cutting edge technology research. They',
      'designed and carried out high quality research projects and are',
      'clearly capable of commercializing the outcomes.',
    ].join(' '),
  },
  {
    image: imranMalik,
    name: 'Imran Malik',
    description: [
      'Signature Expert',
      'German Research Center for AI'
    ],
    quote: [
      "Handwriting verification accuracy has made advances in recent years,",
      "however Cursor Insight's proprietary technology remains top in",
      "internationally recognized algorithm rankings.",
    ].join(' '),
  },
  {
    image: peterErdosi,
    name: 'Péter Erdősi',
    description: ['Certified Information Systems Auditor', ''],
    quote: [
      "By combining clients' handwritten signature with PKI technology,",
      "Cursor Insight has designed and developed a secure process that creates",
      "practically usable advanced e-signatures.",
    ].join(' '),
  },
  {
    image: moritzGrumbach,
    name: 'Moritz Grumbach',
    description: ['Founder & Managing Director', 'Vertum.Group'],
    quote: [
      'Not only have Cursor Insight, and their product Graboxy been one of',
      'the very highlights of the Cybernorth Accelerator program since its',
      'start, but also one of the few genius – and yet performing! – ideas',
      'that one wants to accompany, foster and boost just for its proper',
      'excellence.',
    ].join(' '),
  },
  {
    image: faridSingh,
    name: 'Farid Singh',
    description: ['CEO', 'Take 3 Innovate'],
    quote: [
      'Graboxy is changing how we think of digital sign-on and safety. Think',
      'simple, seamless and single device login, verification and anti fraud,',
      'in one service.',
    ].join(' '),
  }
];

export default TESTIMONIALS;
