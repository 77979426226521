import React from 'react';

import styles from './Spinner.module.scss';

export const ContainerSpinner = () => (
  <div className="container mb-5">
    <div className="row text-center">
      <div className="col-12">
        <Spinner />
      </div>
    </div>
  </div>
);

const Spinner = () => (
  <div className={styles.Spinner} role="status">
    <span className="sr-only">Loading...</span>
  </div>
);

export default Spinner;
