import React from 'react'
import { Link } from 'react-scroll';

import PageLayout from '../../layouts/PageLayout';
import Section from '../../components/Section';
import Seo from '../../components/Seo';

import styles from './LawEnforcement.module.scss';

import WhoWeAre from '../IndexPage/sections/WhoWeAre';
import Partners from '../IndexPage/sections/Partners';
import Testimonials from '../IndexPage/sections/Testimonials';

const LawEnforcement = () => {
  return (
    <PageLayout>
      <Seo title="Cursor Insight | Law Enforcement" />
      <Section
        className={`pt-lg-5 ${styles.LawEnforcement}`}
        title="Law Enforcement Solutions"
      >
        <div className="container">
          <div className="row mb-5 pb-5">
            <div className="col-12 text-center">
              <div className={styles.LawIntro}>
                <p>
                  Protect confidential data, and authenticate employees &amp;
                  users. Enhance your physical security system, and investigate
                  criminal cases with our motion analysis technology.
                </p>
                <p className="py-3">
                  <Link
                    className="mainlink"
                    to="ContactForm"
                    smooth="true"
                  >
                    Get in touch
                  </Link>
                  {' '}
                  if you want to learn more.
                </p>
                <a
                  href="https://calendly.com/tamas-cursor-insight/consultation"
                  rel="noreferrer"
                  target="_blank"
                  className="ButtonPrimaryLg"
                >
                  Book a meeting
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-5">
              <div className="business-card twofa">
                <div>
                  <h3>Forensic analysis</h3>
                  <p>
                    Our gait analysis system enables quick suspect recognition,
                    speeds up crime solving and increases the accuracy of
                    suspect identification. Walking Recognition is designed to
                    generate biometric profiles by analyzing existing CCTV
                    footage and video records. Gait recognition complements or
                    replaces the most common biometric identification methods
                    in forensic science, such as DNA, fingerprint, and facial
                    recognition.
                  </p>
                  <a
                    href="/forensic-analysis"
                    rel="noreferrer"
                    className="ButtonPrimary"
                  >
                    Learn more
                  </a>
                </div>
                <div>
                  <div className="iframe-container">
                    <iframe
                      src="https://player.vimeo.com/video/922004834?h=e589af612e"
                      title="Cursor Insight"
                      frameborder="0"
                      allow="autoplay; picture-in-picture; web-share"
                      allowfullscreen
                    >
                    </iframe>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mb-5">
              <div className="business-card twofa">
                <div>
                  <h3>Workforce authentication</h3>
                  <p>
                    Remote work increases the risk of corporate account
                    takeovers and data breaches. Authenticate employees
                    continuously in the background based on their cursor
                    movement dynamics. These kinds of cursor biometrics cannot
                    be stolen or replicated. Reach your Zero Trust goals with
                    Graboxy Sentinel Desktop.
                  </p>
                  <ul>
                    <li>
                      Seamless integration
                    </li>
                    <li>
                      NIS2 &amp; SOC2 compliant
                    </li>
                    <li>
                      Invisible for users
                    </li>
                  </ul>
                  <a
                    href="https://graboxy.com"
                    rel="noreferrer"
                    target="_blank"
                    className="ButtonPrimary"
                  >
                    Learn more
                  </a>
                </div>
                <div>
                  <div className="iframe-container">
                    <iframe
                      src="https://www.youtube.com/embed/DcanTk-cCps?controls=0"
                      title="Cursor Insight"
                      frameborder="0"
                      allow="autoplay; picture-in-picture; web-share"
                      allowfullscreen
                    >
                    </iframe>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mb-5">
              <div className="business-card sentinel">
                <div>
                  <h3>Online account takeover prevention</h3>
                  <p>
                    Identify users continuously based on their cursor movement
                    dynamics through browsers and prevent online account
                    takeover fraud with Graboxy Sentinel Online. Unlike most
                    types of biometrics, mouse movement characteristics cannot
                    be stolen, shared, or successfully replicated. Maximize the
                    safety of your customers during the whole user session
                    while keeping the UX frictionless.
                  </p>
                  <ul>
                    <li>
                      Works in browsers
                    </li>
                    <li>
                      Invisible for users
                    </li>
                    <li>
                      Seamless integration
                    </li>
                  </ul>
                  <a
                    href="https://graboxy.com"
                    rel="noreferrer"
                    target="_blank"
                    className="ButtonPrimary"
                  >
                    Learn more
                  </a>
                </div>
                <div>
                  <div className="iframe-container">
                    <iframe
                      src="https://www.youtube.com/embed/h5biJ-D959o?controls=0"
                      title="Cursor Insight"
                      frameborder="0"
                      allow="autoplay; picture-in-picture; web-share"
                      allowfullscreen
                    >
                    </iframe>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mb-5">
              <div className="business-card captcha">
                <div>
                  <h3>Multi-factor authentication</h3>
                  <p>
                    Authenticate users invisibly in the background of the
                    user session with Graboxy Sentinel or use an optional
                    5-second movement challenge with Graboxy 2FA. No need to
                    send costly SMS codes for the users to switch between
                    devices and apps. We turn cursor movements into biometric
                    profiles that cannot be stolen or imitated.
                  </p>
                  <ul>
                    <li>
                      No need for additional hardware
                    </li>
                    <li>
                      Easily brandable design
                    </li>
                    <li>
                      Cost efficient
                    </li>
                    <li>
                      PSD2 and SCA compliant
                    </li>
                  </ul>
                  <a
                    href="https://graboxy.com"
                    rel="noreferrer"
                    target="_blank"
                    className="ButtonPrimary"
                  >
                    Learn more
                  </a>
                </div>
                <div>
                  <div className="iframe-container">
                    <iframe
                      src="https://www.youtube.com/embed/Hog8cVGuQY0?controls=0"
                      title="Cursor Insight"
                      frameborder="0"
                      allow="autoplay; picture-in-picture; web-share"
                      allowfullscreen
                    >
                    </iframe>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mb-5">
              <div className="business-card ci">
                <div>
                  <h3>Walking Recognition</h3>
                  <p>
                    Our CCTV gait recognition technology analyzes how people
                    walk on live video footage. Identify unauthorized persons
                    entering premises or using someone else’s RFID card by
                    deploying our walking recognition system.
                  </p>
                  <ul>
                    <li>
                      Gross motor analysis with 3D modeling
                    </li>
                    <li>
                      No need to invest in new hardware
                    </li>
                    <li>
                      Protect personal data
                    </li>
                  </ul>
                  <a
                    href="https://www.cursorinsight.com/walking-recognition"
                    rel="noreferrer"
                    target="_blank"
                    className="ButtonPrimary"
                  >
                    Learn more
                  </a>
                </div>
                <div>
                  <div className="iframe-container">
                    <iframe
                      src="https://player.vimeo.com/video/922004834?h=e589af612e"
                      title="Cursor Insight"
                      frameborder="0"
                      allow="autoplay; picture-in-picture; web-share"
                      allowfullscreen
                    >
                    </iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <WhoWeAre />
      <Partners />
      <Testimonials />
    </PageLayout>
  );
};

export default LawEnforcement;
