import Section from '../../../../components/Section';
import styles from './Team.module.scss';

import Gergely from '../../images/gergely.jpg';
import Bence from '../../images/bence.jpg';
import Agnes from '../../images/agnes.jpg';
import Mate from '../../images/mate.jpg';
import Oliver from '../../images/oliver.jpg';
import Nora from '../../images/nora.jpg';
import Lorand from '../../images/lorand.jpg';
import Anita from '../../images/anita.jpg';
import Cecilia from '../../images/cecilia.jpg';

function Team() {
  return (
    <>
      <Section
        title="Meet The Team"
        className={styles.Team}
        maxWidth={1080}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <img src={Gergely} alt="Member" />
              <h4><span>Gergely</span> Hanczár, PhD</h4>
              <p>Founder & CEO</p>
            </div>
            <div className="col-lg-4 col-md-6">
              <img src={Bence} alt="Member" />
              <h4><span>Bence</span> Borély, PhD</h4>
              <p>Lead AI Engineer</p>
            </div>
            <div className="col-lg-4 col-md-6">
              <img src={Agnes} alt="Member" />
              <h4><span>Ágnes</span> Chripkó, PhD</h4>
              <p>Data Ninja</p>
            </div>
            <div className="col-lg-4 col-md-6">
              <img src={Mate} alt="Member" />
              <h4><span>Máté</span> Palicz</h4>
              <p>Business Developer</p>
            </div>
            <div className="col-lg-4 col-md-6">
              <img src={Oliver} alt="Member" />
              <h4><span>Olivér</span> Törteli</h4>
              <p>CTO</p>
            </div>
            <div className="col-lg-4 col-md-6">
              <img src={Nora} alt="Member" />
              <h4><span>Nóra</span> Ovád</h4>
              <p>Junior Data Engineer</p>
            </div>
          </div>
        </div>
      </Section>
      <Section
        title="Medical Advisory Board"
        className={styles.Team}
        maxWidth={1080}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="text-center mb-5">
                Comprised of distinguished physicians, our Medical Advisory
                Board members serve as strategic advisors to
                MotionAnalysis.Health and were chosen for their
                multidisciplinary expertise and thought-leadership.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <img src={Lorand} alt="Member" />
              <h4><span>Loránd</span> Prof. Dr. Erőss</h4>
              <p>med. habil., MD, PhD</p>
            </div>
            <div className="col-lg-4 col-md-6">
              <img src={Anita} alt="Member" />
              <h4><span>Anita</span> Prof. Dr. Kamondi</h4>
              <p>MD, PhD, DSc HAS</p>
            </div>
            <div className="col-lg-4 col-md-6">
              <img src={Cecilia} alt="Member" />
              <h4><span>Cecília</span> Dr. Rajda</h4>
              <p>MD, PhD</p>
            </div>
          </div>
        </div>
      </Section>
    </>
  );
}

export default Team;
