import Section from '../../../../components/Section';
import styles from './Featured.module.scss';

import fintech from '../../../../images/featured-cee-fintech.png';
import fintech2 from '../../../../images/featured-cee-fintech-colored.png';
import biometric from '../../../../images/featured-biometric-update.png';
import biometric2 from '../../../../images/featured-biometric-update-colored.png';
import portfolio from '../../../../images/featured-portfolio.png';
import portfolio2 from '../../../../images/featured-portfolio-colored.png';
import isosec from '../../../../images/featured-isosec.png';
import isosec2 from '../../../../images/featured-isosec-colored.png';
import forbes from '../../../../images/featured-forbes.png';
import forbes2 from '../../../../images/featured-forbes-colored.png';
import producthunt from '../../../../images/featured-producthunt.png';
import producthunt2 from '../../../../images/featured-producthunt-colored.png';
import magazine from '../../../../images/featured-magazine.png';
import magazine2 from '../../../../images/featured-magazine-colored.png';
import telex from '../../../../images/featured-telex.png';
import telex2 from '../../../../images/featured-telex-colored.png';
import biometrics from '../../../../images/featured-biometrics.png';
import biometrics2 from '../../../../images/featured-biometrics-colored.png';

const Featured = () => (
  <Section
    title="Featured in"
    className="mb-5 pt-5"
    maxWidth={1170}
    style={{ margin: '0 auto' }}
  >
    <div className="row text-center">
      <div className="col-12">
        <div className={styles.Featured}>
          <div className="d-flex flex-wrap justify-content-center">
            <div style={{width: 240, display: 'block'}}>
              <a
                href="https://www.biometricupdate.com/202204/real-time-movement-biometrics-to-protect-against-enterprise-account-takeover"
                target="_blank"
                rel="noreferrer"
              >
                <img src={biometric} alt="biometric" />
                <img src={biometric2} alt="biometric" />
              </a>
            </div>
            <div style={{width: 240, display: 'block'}}>
              <a href="/downloads/ci-forbes-interview.pdf" download>
                <img src={forbes} alt="forbes" />
                <img src={forbes2} alt="forbes" />
              </a>
            </div>
            <div style={{width: 240, display: 'block'}}>
              <a href="/downloads/cyber-defense-magazine.pdf" download>
                <img src={magazine} alt="magazine" />
                <img src={magazine2} alt="magazine" />
              </a>
            </div>
            <div style={{width: 240, display: 'block'}}>
              <a href="/downloads/ci-portfolio-interview.pdf" download>
                <img src={portfolio} alt="portfolio" />
                <img src={portfolio2} alt="portfolio" />
              </a>
            </div>
            <div style={{width: 240, display: 'block'}}>
              <a
                href="https://www.producthunt.com/posts/graboxy-maze-captcha"
                target="_blank"
                rel="noreferrer"
              >
                <img src={producthunt} alt="producthunt" />
                <img src={producthunt2} alt="producthunt" />
              </a>
            </div>
            <div style={{width: 240, display: 'block'}}>
              <a
                href="http://www.cee-fintech.com/index.php/2016/11/03/cursor-movement-based-user-identification-ad-targeting-and-signature-veryfication-interview-with-tamas-zelczer-ceo-at-cursor-insight/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={fintech} alt="fintech" />
                <img src={fintech2} alt="fintech" />
              </a>
            </div>
            <div style={{width: 240, display: 'block'}}>
              <a
                href="https://150sec.com/cursor-insight-builds-worlds-most-precise-signature-analysis-algorithm/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={isosec} alt="isosec" />
                <img src={isosec2} alt="isosec" />
              </a>
            </div>
            <div style={{ width: 240, display: 'block' }}>
              <a href="/downloads/telex.pdf" download>
                <img src={telex} alt="telex" />
                <img src={telex2} alt="telex" />
              </a>
            </div>
            <div style={{ width: 240, display: 'block' }}>
              <a
                href="https://findbiometrics.com/id-talk-podcast-cursor-insight-705249/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={biometrics} alt="biometrics" />
                <img src={biometrics2} alt="biometrics" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Section>
);

export default Featured;
