import isMobile from 'is-mobile';

export const GRABOXY_URL = 'https://graboxy.com';

export const CUSTOM_FORM_URL =
  process.env.REACT_APP_CUSTOM_FORM_URL ?? '';

export const WP_API_URL =
  process.env.REACT_APP_WP_API_URL ?? '';

export const GRABOXY_CAPTCHA_SITE_KEY =
  process.env.REACT_APP_GRABOXY_CAPTCHA_SITE_KEY ?? '';

export const GRABOXY_CAPTCHA_SCRIPT_URL =
  process.env.REACT_APP_GRABOXY_CAPTCHA_SCRIPT_URL ?? '';

export const IS_MOBILE = isMobile();

export const CAPTCHA_URL_BASE = process.env.REACT_APP_CAPTCHA_URL_BASE
  ?? 'https://captcha.graboxy.com/?UNCONFIGURED=1';

export const GRABOXY_API_KEY =
  process.env.REACT_APP_GRABOXY_API_KEY ?? 'UNCONFIGURED';

export const TRAP_SERVER_URL = process.env.REACT_APP_TRAP_SERVER_URL
  ?? 'https://trap.graboxy.com/?UNCONFIGURED=1';
