import { Link } from 'react-router-dom';
import Section from '../../../../components/Section';
import styles from './Solutions.module.scss';

import handGestures from '../../../../images/movement_analysis_hand_gestures.svg';
import fineMotor from '../../../../images/movement_analysis_fine_motor.svg';
import handWriting from '../../../../images/movement_analysis_handwriting.svg';
import eyeMovement from '../../../../images/movement_analysis_eyemovement.svg';
import walkingBalance from '../../../../images/movement_analysis_walking_balance.svg';
import heartRate from '../../../../images/movement_analysis_heart_rate.svg';
import computerMouse from '../../../../images/movement_analysis_computermouse.svg';
import touchpadScreen from '../../../../images/movement_analysis_touchpad_screen.svg';
import keyboard from '../../../../images/movement_analysis_keyboard.svg';
import wearableFitness from '../../../../images/movement_analysis_wearable_fitness.svg';
import smartphone from '../../../../images/movement_analysis_smartphone_gyroscope.svg';
import webcam from '../../../../images/movement_analysis_webcam.svg';

const Solutions = () => (
  <Section
    className={styles.Solutions}
    title="Custom-built AI solutions"
    maxWidth={1080}
  >
    <div className="row">
      <div className="col-12">
        <p style={{ color: 'white', fontSize: '20px', textAlign: 'center' }}>
          Hand gestures, eye and body movements often reveal more information
          than spoken words. Human movement is incredibly complex and its
          research is still in its early stages. To help solve movement
          analysis challenges, we developed a programming language that
          describes human motion, and a toolchain that can classify movement
          samples. We can extract and process movement data gathered with a
          wide range of devices.
        </p>
        <p style={{ color: 'white', fontSize: '20px', textAlign: 'center' }}>
          Check out our
          {' '}
          <Link to="/blog" className="mainlink">
            blog
          </Link>
          {' '}
          to learn more.
        </p>
      </div>
    </div>
    <div className="row mb-5 py-5">
      <div className="d-flex flex-lg-row flex-column justify-content-between align-items-center text-white">
        <div className={styles.ListItem}>
          <div className="d-flex flex-column align-items-center">
            <div className="pb-4">
              <h3>Motion Data</h3>
            </div>
            <div className="d-md-flex w-100 justify-content-between">
              <div className={styles.ListItemInner}>
                <div className="d-flex w-100 justify-content-between align-items-center mb-4 px-4">
                  <p className="my-0">Gestures</p>
                  <div className="d-flex justify-content-center">
                    <img src={handGestures} alt="" />
                  </div>
                </div>
                <div className="d-flex w-100 justify-content-between align-items-center mb-4 px-4">
                  <p className="my-0">Fine motor</p>
                  <div className="d-flex justify-content-center">
                    <img src={fineMotor} alt="" />
                  </div>
                </div>
                <div className="d-flex w-100 justify-content-between align-items-center mb-4 px-4">
                  <p className="my-0">Handwriting</p>
                  <div className="d-flex justify-content-center">
                    <img src={handWriting} alt="" />
                  </div>
                </div>
              </div>
              <div className={styles.ListItemInner}>
                <div className="d-flex w-100 justify-content-between align-items-center mb-4 px-4">
                  <p className="my-0">Eye Movement</p>
                  <div className="d-flex justify-content-center">
                    <img src={eyeMovement} alt="" />
                  </div>
                </div>
                <div className="d-flex w-100 justify-content-between align-items-center mb-4 px-4">
                  <p className="my-0">Gait</p>
                  <div className="d-flex justify-content-center">
                    <img src={walkingBalance} alt="" />
                  </div>
                </div>
                <div className="d-flex w-100 justify-content-between align-items-center mb-4 px-4">
                  <p className="my-0">Biosignals</p>
                  <div className="d-flex justify-content-center">
                    <img src={heartRate} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ListItem}>
          <div className="d-flex flex-column align-items-center">
            <div className="pb-4">
              <h3>Motion Sensors</h3>
            </div>
            <div className="d-md-flex w-100 justify-content-between">
              <div className={styles.ListItemInner}>
                <div className="d-flex w-100 justify-content-between align-items-center mb-4 px-4">
                  <p className="my-0">Mouse</p>
                  <div className="d-flex justify-content-center">
                    <img src={computerMouse} alt="" />
                  </div>
                </div>
                <div className="d-flex w-100 justify-content-between align-items-center mb-4 px-4">
                  <p className="my-0">Touchscreen</p>
                  <div className="d-flex justify-content-center">
                    <img src={touchpadScreen} alt="" />
                  </div>
                </div>
                <div className="d-flex w-100 justify-content-between align-items-center mb-4 px-4">
                  <p className="my-0">Keyboard</p>
                  <div className="d-flex justify-content-center">
                    <img src={keyboard} alt="" />
                  </div>
                </div>
              </div>
              <div className={styles.ListItemInner}>
                <div className="d-flex w-100 justify-content-between align-items-center mb-4 px-4">
                  <p className="my-0">Camera</p>
                  <div className="d-flex justify-content-center">
                    <img src={webcam} alt="" />
                  </div>
                </div>
                <div className="d-flex w-100 justify-content-between align-items-center mb-4 px-4">
                  <p className="my-0">Phone</p>
                  <div className="d-flex justify-content-center">
                    <img src={smartphone} alt="" />
                  </div>
                </div>
                <div className="d-flex w-100 justify-content-between align-items-center mb-4 px-4">
                  <p className="my-0">Wearable</p>
                  <div className="d-flex justify-content-center">
                    <img src={wearableFitness} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Section>
);

export default Solutions;
