import PropTypes from 'prop-types';

import './FaIcon.scss';

const FaIcon = ({ id, href }) => (
  <a
    href={href}
    target="_blank"
    rel="noreferrer"
    className={`fa-stack fa-lg mx-1 mx-lg-0 me-lg-2 FaIcon`}
  >
    <i className="fa fa-circle fa-stack-2x" />
    <i className={`fa ${id} fa-stack-1x fa-inverse`} />
  </a>
);

FaIcon.propTypes = {
  id: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

export default FaIcon;
