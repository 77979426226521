import PageLayout from '../../layouts/PageLayout';
import Section from '../../components/Section';
import Seo from '../../components/Seo';
import Slider from 'react-slick';
import BlogTeaser from '../Blog/components/BlogTeaser';

import styles from './Resources.module.scss';
import './Resources.scss';

import whitepaper from './whitepaper.png';
import poster1 from './poster1.png';
import poster2 from './poster2.png';
import publication0 from './Publication0.png';
import publication1 from './Publication1.png';
import publication2 from './Publication2.png';
import publication3 from './Publication3.png';
import publication4 from './Publication4.png';

import pdf from './Cursor-Insight-Whitepaper.pdf'

const SLIDER_SETTINGS = {
  dots: true,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: true,
  swipeToSlide: true,
  speed: 750,
  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnFocus: true,
  cssEase: 'ease'
};

const Resources = () => {
  return (
    <PageLayout>
      <Seo title="Cursor Insight | Resources" />
      <div className="mt-lg-5 pb-2">
        <Section title="Case studies" id="use-cases">
          <div className={styles.CaseStudies}>
            <div className="row">
              <div className="col-lg-6">
                <a
                  className={styles.Card}
                  href="/casestudy/online-user-authentication-banking"
                >
                  <h3>Continuous Online User Authentication in Banking</h3>
                  <p>
                    <strong>Goal:</strong> Decrease the number of fraudulent
                    online banking transactions and reduce the cost of
                    transaction authorization
                  </p>
                  <a
                    href="/casestudy/online-user-authentication-banking"
                    className="mainlink"
                  >
                    Read more
                  </a>
                </a>
              </div>
              <div className="col-lg-6">
                <a
                  className={styles.Card}
                  href="/casestudy/detecting-dementia"
                >
                  <h3>
                    Detecting Dementia by Analyzing Cursor Movement Patterns
                  </h3>
                  <p>
                    <strong>Goal:</strong> A cost-efficient and easy-to-use
                    screening method for Mild Cognitive Impairment detection
                  </p>
                  <a
                    href="/casestudy/detecting-dementia"
                    className="mainlink"
                  >
                    Read more
                  </a>
                </a>
              </div>
              <div className="col-lg-6">
                <a className={styles.Card} href="/casestudy/drone-detection">
                  <h3>Drone Detection Using Radar Data</h3>
                  <p>
                    <strong>Goal:</strong> Identify commercial drones and
                    differentiate them from birds by using airport radar
                    infrastructure
                  </p>
                  <a
                    href="/casestudy/drone-detection"
                    className="mainlink"
                  >
                    Read more
                  </a>
                </a>
              </div>
              <div className="col-lg-6">
                <a
                  className={styles.Card}
                  href="/casestudy/clinical-neurosciences"
                >
                  <h3>
                    Human Motion Analysis Technology in Clinical Neurosciences
                  </h3>
                  <p>
                    <strong>Goal:</strong> Enhance clinical assessments and
                    research with motion analysis AI tools
                  </p>
                  <a
                    href="/casestudy/clinical-neurosciences"
                    className="mainlink"
                  >
                    Read more
                  </a>
                </a>
              </div>
              <div className="col-lg-6 offset-lg-3">
                <a
                  className={styles.Card}
                  href="/casestudy/biometric-esignature-banking"
                >
                  <h3>
                    Biometric E-Signatures in Banking
                  </h3>
                  <p>
                    <strong>Goal:</strong> Goal: Digitizing the contracting
                    process in bank branches
                  </p>
                  <a
                    href="/casestudy/biometric-esignature-banking"
                    className="mainlink"
                  >
                    Read more
                  </a>
                </a>
              </div>
            </div>
          </div>
        </Section>
      </div>
      <div className="mt-lg-5 pb-2" id="blog">
        <BlogTeaser />
      </div>
      <Section
        maxWidth={1020}
        className={styles.Resources}
        id="whitepapers"
      >
        <div className="row">
          <div className="col-lg-12 resources-slider">
            <h2>Whitepaper</h2>
            <Slider {...SLIDER_SETTINGS}>
              <div className={styles.ResourcesSlide}>
                <div>
                  <h3>
                    Cursor Insight's technology whitepaper
                  </h3>
                  <p>
                    Discover what's under the hood
                  </p>
                  <a
                    href={pdf}
                    className="ButtonPrimary"
                    download
                  >
                    Download
                  </a>
                </div>
                <div>
                  <img src={whitepaper} alt="whitepaper" />
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </Section>
      <Section
        title="Scientific papers"
        className={styles.Resources}
        maxWidth={1020}
        id="scientific-papers"
      >
        <div className="row">
          <div className="col-12">
            <p className={styles.SmallTitle}>
              (The ones that are not Trade Secrets)
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 resources-slider">
            <Slider {...SLIDER_SETTINGS}>
              <div className={styles.ResourcesSlide}>
                <div>
                  <h3>
                    Feature space reduction method for ultrahigh-dimensional,
                    multiclass data: RFMS
                  </h3>
                  <p>
                    Gergely Hanczár, Marcell Stippinger, Dávid Hanák, Marcell
                    T. Kurbucz, Olivér Máté Törteli, Ágnes Chripkó and Zoltán
                    Somogyvári
                  </p>
                  <a
                    href="https://iopscience.iop.org/article/10.1088/2632-2153/ad020e"
                    rel="noreferrer"
                    target="_blank"
                    className="ButtonPrimary"
                  >
                    Read more
                  </a>
                </div>
                <div>
                  <img src={publication0} alt="publication" />
                </div>
              </div>

              <div className={styles.ResourcesSlide}>
                <div>
                  <h3>
                    Detection of mild cognitive impairment based on mouse
                    movement data of trail making test
                  </h3>
                  <p>
                    Dr. Gergely Hanczár, Dr. Erika Griechisch, Nóra Ovád,
                    Olivér Máté Törteli, Gábor Tóth, Dávid Hanák, Balázs
                    Vértes, Dr. András Horváth &amp; Prof. Dr. Anita Kamondi
                  </p>
                  <a
                    href="https://www.sciencedirect.com/science/article/pii/S235291482200257X"
                    rel="noreferrer"
                    target="_blank"
                    className="ButtonPrimary"
                  >
                    Read more
                  </a>
                </div>
                <div>
                  <img src={publication1} alt="publication" />
                </div>
              </div>
              <div className={styles.ResourcesSlide}>
                <div>
                  <h3>
                    BiometricBlender: Ultra-high dimensional, multi-class
                    synthetic data generator to imitate biometric feature space
                  </h3>
                  <p>
                    Dr. Gergely Hanczár, Olivér Máté Törteli, Dávid Hanák,
                    Marcell Stippinger, Marcell T. Kurbucz &amp; Zoltan Somogyvari
                  </p>
                  <a
                    href="https://www.softxjournal.com/article/S2352-7110(23)00062-6/fulltext"
                    rel="noreferrer"
                    target="_blank"
                    className="ButtonPrimary"
                  >
                    Read more
                  </a>
                </div>
                <div>
                  <img src={publication2} alt="publication" />
                </div>
              </div>
              <div className={styles.ResourcesSlide}>
                <div>
                  <h3>
                    Motor Movement Data Collection from Older People with
                    Tablets and Digital Pen-Based Input Devices
                  </h3>
                  <p>
                    Dr. Gergely Hanczár, Dr. Erika Griechisch, Ákos Molnár &amp;
                    Gábor Tóth
                  </p>
                  <a
                    href="https://link.springer.com/chapter/10.1007/978-3-031-19745-1_18"
                    rel="noreferrer"
                    target="_blank"
                    className="ButtonPrimary"
                  >
                    Read more
                  </a>
                </div>
                <div>
                  <img src={publication3} alt="publication" />
                </div>
              </div>
              <div className={styles.ResourcesSlide}>
                <div>
                  <h3>
                    Anomalies in measuring speed and other dynamic properties
                    with touchscreens and tablets
                  </h3>
                  <p>
                    Erika Griechisch, Jean Renard Ward &amp; Gergely Hanczár
                  </p>
                  <a
                    href="https://ieeexplore.ieee.org/document/8897249"
                    rel="noreferrer"
                    target="_blank"
                    className="ButtonPrimary"
                  >
                    Read more
                  </a>
                </div>
                <div>
                  <img src={publication4} alt="publication" />
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </Section>

      <Section
        maxWidth={1020}
        className={styles.Resources}
        id="posters"
      >
        <div className="row">
          <div className="col-lg-12 resources-slider">
            <h2>Posters</h2>
            <Slider {...SLIDER_SETTINGS}>
              <div className={styles.ResourcesSlide}>
                <div>
                  <h3>
                    Anomalies in measuring speed and other dynamic properties
                    with touchscreens and tablets
                  </h3>
                  <p>
                    Erika Griechisch, Jean Renard Ward &amp; Gergely Hanczár
                  </p>
                  <a
                    href="/downloads/Anomalies-in-measuring-speed-and-other-dynamic-properties-with-touchscreens-and-tablets.pdf"
                    className="ButtonPrimary"
                    download
                  >
                    Download
                  </a>
                </div>
                <div>
                  <img src={poster1} alt="poster" />
                </div>
              </div>
              <div className={styles.ResourcesSlide}>
                <div>
                  <h3>
                    Motor Movement Data Collection from Older People with
                    Tablets and Digital Pen-based Input Devices
                  </h3>
                  <p>
                    Dr. Gergely Hanczár, Dr. Erika Griechisch, Ákos Molnár &amp;
                    Gábor Tóth
                  </p>
                  <a
                    href="/downloads/Motor-Movement-Data-Collection-from-Older-People-with-Tablets-and-Digital-Pen-based-Input-Devices.pdf"
                    className="ButtonPrimary"
                    download
                  >
                    Download
                  </a>
                </div>
                <div>
                  <img src={poster2} alt="poster" />
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </Section>
    </PageLayout>
  );
};

export default Resources;
