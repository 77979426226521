import React from 'react';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';

import Pager from './Pager';
import './Pagination.scss';

const VALUES = [
  <i className="fa fa-arrow-left" />,
  <i className="fa fa-chevron-left" />,
  '',
  <i className="fa fa-chevron-right" />,
  <i className="fa fa-arrow-right" />,
];

function generatePagers(current, max, search) {
  const pages = [-current, -1, 0, 1, max - current].map(
    (v) => Math.max(1, Math.min(max, v + current))
  );

  const pagers = [];

  pages.forEach((v, i) => {
    const value = i === 2 ? v.toString() : VALUES[i];

    pagers.push(
      <Pager
        key={i}
        to={`/blog/${v}${search}`}
        value={i === 2 ? `${value} / ${max}` : value}
        active={i === 2}
      />
    );
  });

  return pagers;
}

const Pagination = ({ currentPage, totalPages }) => {
  const { search } = useLocation();
  const pagers = generatePagers(currentPage, totalPages, search);

  if (totalPages < 2) {
    return <></>;
  }

  return (
    <div className="row mt-4">
      <div className="col-12">
        <nav aria-label="Blog pagination" className="Pagination">
          <ul className="pagination pagination-lg justify-content-center">
            {pagers}
          </ul>
        </nav>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
};

Pagination.defaultProps = {
  currentPage: 1,
  totalPages: 1,
};

export default Pagination;
