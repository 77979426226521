import Section from '../../../../components/Section';
import styles from './Numbers.module.scss';

function Numbers() {
  return (
    <Section
      title="We Take Pride in Our Numbers"
      maxWidth={1080}
    >
      <div className="container my-5">
        <div className="row">
          <div className="col-lg-3">
            <div className={styles.Box}>
              <h3>3</h3>
              <p>
                hundred years of combined experience
              </p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className={styles.Box}>
              <h3>4</h3>
              <p>
                million biometric profiles created
              </p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className={styles.Box}>
              <h3>5</h3>
              <p>
                million USD revenue
              </p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className={styles.Box}>
              <h3>12</h3>
              <p>
                industry<br /> awards
              </p>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default Numbers;
