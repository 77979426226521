import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import styles from './Tag.module.scss';

const Tags = ({ tags }) => {
  const len = tags.length;
  const renderedTags = tags.map(({ id, link, name }, i) => (
    <>
      <Link key={id} to={link} className={styles.Tag}>
        {name}
      </Link>
      {(len > 1 && i < len - 1) && ', '}
    </>
  ));

  return (
    <div className="align-self-center">
      {renderedTags}
    </div>
  );
};

Tags.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
};

Tags.defaultProps = {
  tags: [],
};

export default Tags;
