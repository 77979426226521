import { IS_MOBILE } from '../../constants';
import PageLayout from '../../layouts/PageLayout';
import PageTitle from '../../components/PageTitle';
import Section from '../../components/Section';
import usePosts from './hooks/usePosts';
import SearchBar from './components/SearchBar';
import ErrorMessage from './components/ErrorMessage';
import ResultsFor from './components/ResultFor';
import Posts from './components/Posts';
import Pagination from './components/Pagination';
import Seo from '../../components/Seo';

const BlogPage = () => {
  const {
    isLoading,
    currentPage,
    totalPages,
    resultsFor,
    posts,
    error,
  } = usePosts({ limit: IS_MOBILE ? 3 : 12 });

  return (
    <PageLayout>
      <Seo title="Cursor Insight | Blog" />

      <div className="mt-5">
        <PageTitle>The Cursor Insight Blog</PageTitle>
      </div>

      <Section className="my-5">
        <SearchBar />

        {(!isLoading && error) && (
          <ErrorMessage content={error.toString()} />
        )}

        {resultsFor && <ResultsFor>{resultsFor}</ResultsFor>}

        {isLoading || (
          <>
            <Posts posts={posts} />
            <Pagination currentPage={currentPage} totalPages={totalPages} />
          </>
        )}
      </Section>
    </PageLayout>
  );
};

export default BlogPage;
