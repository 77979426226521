import { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router';

import useTags from '../../hooks/useTags';
import Tag from './Tag';
import styles from './SearchBar.module.scss';

const SearchBar = () => {
  const inputRef = useRef();
  const [toggle, setToggle] = useState(false);
  const history = useHistory();
  const { tags } = useTags();

  const onKeyDown = useCallback((event) => {
    if (event.key === 'Enter') {
      if (!inputRef.current) {
        return;
      }

      const value = encodeURIComponent(inputRef.current.value);

      if (value) {
        inputRef.current.value = '';
        history.push(`/blog/1?search=${value}`);
      }
    }
  }, [inputRef, history]);

  const onClick = useCallback(() => {
    setToggle((toggle) => !toggle);
  }, [setToggle]);

  const renderedTags = tags.map((tag) => (
    <Tag key={tag.id} tag={tag} />
  ));

  const tagStyle = [
    'col-12 col-md-8 mt-1',
    toggle || styles.TagsClosed,
  ].join(' ');

  return (
    <div className={`row mb-2 ${styles.SearchBar}`}>
      <div className="col">
        <div className="row py-2 align-items-top">
          <div className="col-12 col-md-4 pb-2 pb-md-0">
            <div className="input-group">
              <input
                ref={inputRef}
                onKeyDown={onKeyDown}
                className="form-control border-0"
              />
              <span className="input-group-text border-0 bg-white">
                <i className="fa fa-search" />
              </span>
            </div>
          </div>
          <div className={tagStyle}>
            Tag filter: {renderedTags}
          </div>
        </div>
      </div>

      <div className="col-1">
        <button type="button" className="btn text-white" onClick={onClick}>
          {toggle ? (
            <i className="fa fa-caret-up" />
          ) : (
            <i className="fa fa-caret-down" />
          )}
        </button>
      </div>
    </div>
  );
};

export default SearchBar;
