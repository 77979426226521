import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import axios from 'axios';

import { WP_API_URL } from '../../../constants';
import formatPosts from '../utils/formatPosts';

export default function usePosts(options) {
  const [state] = useState({
    limit: 12,
    path: 'posts',
    ...options
  });

  const { search } = useLocation();
  const { page } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [resultsFor, setResultsFor] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    const { limit, path } = state;

    setIsLoading(true);
    setResultsFor(undefined);
    setError(undefined);

    const params = new URLSearchParams(search);
    params.append('_embed', '');
    params.append('per_page', limit.toString());
    params.append('page', page || '1');

    const slug = params.get('slug');

    if (slug) {
      params.delete('slug');
      setResultsFor(slug);
    }

    const query = params.get('search');

    if (query) {
      setResultsFor(decodeURIComponent(query));
    }

    axios.get(`${WP_API_URL}/${path}?${params}`)
      .then((response) => {
        setTotalPages(response.headers['x-wp-totalpages']);
        setPosts(response.data);
      })
      .catch((error) => setError(error))
      .finally(() => setIsLoading(false));
  }, [
    state,
    search,
    page,
    setIsLoading,
    setResultsFor,
    setTotalPages,
    setPosts,
    setError,
  ]);

  return {
    isLoading,
    resultsFor,
    currentPage: parseInt(page),
    totalPages: parseInt(totalPages),
    posts: formatPosts(posts),
    error,
  };
}
