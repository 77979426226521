import Section from '../Section';
import inverseLogo from './inverse-logo-cursor-insight.svg';
import './logo-header.scss';

const LogoHeader = () => (
  <Section className="py-4 logoHeader">
    <div className="row text-center">
      <div className="col-12">
        <img src={inverseLogo} alt="Cursor Insight Logo" className="img-fluid" style={{ maxWidth: 400 }} />
      </div>
    </div>
  </Section>
);

export default LogoHeader;
