import Section from '../../../../components/Section';
import styles from './Hiring.module.scss';
import { Link } from 'react-router-dom';

import hiringIcon from '../../../../images/hiring-icon.svg';

const Hiring = () => (
  <Section className="my-5">
    <div className="row px-0 px-md-2">
      <div className="col-12 px-0 px-md-2">
        <div className={styles.Hiring}>
          <div className={styles.HiringInner}>
            <h3>
              We are hiring!<br/>
              Get to know us and what we offer
            </h3>
            <Link to="/our-team" className="ButtonPrimary mt-4">
              Who we are
            </Link>
          </div>
          <div className={styles.HiringInner}>
            <img src={hiringIcon} alt="hiringIcon" />
            <div>
              <ul>
                <li>
                  <Link to="/our-team">
                    Team
                  </Link>
                </li>
                <li>
                  <Link to="/our-team">
                    Founders
                  </Link>
                </li>
                <li>
                  <Link to="/our-team">
                    Culture
                  </Link>
                </li>
                <li>
                  <Link to="/our-team">
                    Roles
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Section>
);

export default Hiring;
