import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ReactComponent as ArrowLeftIcon } from './arrow-left.svg';
import styles from './BackToLink.module.scss';

const BackToLink = ({ to, children }) => (
  <Link to={to} className={styles.BackToLink}>
    <ArrowLeftIcon />
    {children}
  </Link>
);

BackToLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
};

BackToLink.defaultProps = {
  to: '/',
  children: 'Back to home',
};

export default BackToLink;
