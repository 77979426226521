import PageLayout from '../../layouts/PageLayout';
import Section from '../../components/Section';
import PageTitle from '../../components/PageTitle';
import Seo from '../../components/Seo';

import styles from './Gdpr.module.scss';

const Gdpr = () => {
  return (
    <PageLayout>
      <Seo title="Cursor Insight | GDPR" />
      <Section className={styles.Gdpr}>
        <PageTitle>GDPR Policy</PageTitle>
        <div className="row text-center">
          <div className="col-lg-6 col-12">
            <h2>
              ADATKEZELÉSI TÁJÉKOZTATÓ
            </h2>
            <p>
              – Jelöltek személyes adatainak kezelésével kapcsolatban –
            </p>
          </div>
          <div className="col-lg-6 col-12">
            <h2>
              PRIVACY POLICY
            </h2>
            <p>
              - About the processing of applicants' personal data -
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-12">
            <p>
              A Cursor Insight Hungary Korlátolt Felelősségű Társaság
              (cégjegyzékszám: 01-09-276894, adószám: 25467855-2-43, székhely:
              1121 Budapest, Árnyas út 38-40. C/4. ajtó, az „Adatkezelő”) a
              jelen dokumentumban („Tájékoztató”) az Európai Parlament és a
              Tanács 2016. április 27-i (EU) 2016/679 rendelete a természetes
              személyeknek a személyes adatok kezelése tekintetében történő
              védelméről és az ilyen adatok szabad áramlásáról, valamint a
              95/46/EK irányelv hatályon kívül helyezéséről („GDPR”), továbbá
              az információs önrendelkezési jogról és információszabadságról
              szóló 2011. évi CXII. törvény („Info. törvény”) alapján
              tájékoztatja az Adatkezelőnél nyitott állásra jelentkező
              személyeket (a továbbiakban: „Jelölt” vagy „Érintett”) a
              személyes adataik Adatkezelő általi kezeléséről.
            </p>
          </div>
          <div className="col-lg-6 col-12">
            <p>
              In this document ("Policy"), Cursor Insight Hungary Korlátolt
              Felelősségű Társaság (company registration number: 01-09-276894,
              tax number: 25467855-2-43, registered office: 1121 Budapest,
              Árnyas út 38-40. door C/4, the "Controller") informs persons
              applying for a job vacancy at the Controller ("Applicant" or
              "Data Subject") of the processing of their personal data by the
              Controller, based on Regulation (EU) 2016/679 of the European
              Parliament and of the Council of 27 April 2016 on the protection
              of natural persons with regard to the processing of personal data
              and on the free movement of such data, and repealing Directive
              95/46/EC („GDPR”) and Act CXII of 2011 on the right to
              informational self-determination and on the freedom of
              information („Act on Information”).
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-12">
            <h5>
              1. Érintettek és a kezelt személyes adatok, az adatkezelés célja,
              jogalapja és időtartama:
            </h5>
            <p>
              A Jelölt az Adatkezelő által üzemeltetett www.cursorinsight.com
              oldalon a „Job application form” kitöltésével jelentkezhet az
              Adatkezelő valamely pozíciójára. Az Adatkezelő álláshirdetésre
              jelentkezés esetén az alábbiak szerint kezel személyes adatokat:
            </p>
          </div>
          <div className="col-lg-6 col-12">
            <h5>
              1. Data subjects and personal data processed, purpose, legal
              basis and duration of processing:
            </h5>
            <p>
              The Applicant can apply for a position at the Controller's
              website www.cursorinsight.com, by filling out the “Job
              application form”. The Controller processes personal data when
              applying for the job advertisement as follows:
            </p>
          </div>
        </div>
        <div className="row">
          <table>
            <thead>
              <th>
                Kezelt személyes adat/ Personal data processed
              </th>
              <th>
                Adatkezelés célja/ Purpose of data processing
              </th>
              <th>
                Adatkezelés jogalapja/ Legal basis for data processing
              </th>
              <th>
                Adatkezelés időtartama / Duration of processing
              </th>
            </thead>
            <tbody>
              <tr>
                <td>
                  Név/ Name
                  <br/>
                  Pozíció / Position
                  <br/>
                  Bérigény / Salary request
                  <br/>
                  Önéletrajzban, motivációs levélben szereplő egyéb személyes
                  <br/>
                  adatok / Other personal data in CV, cover letter
                  <br/>
                  E-mail cím / E-mail address
                  <br/>
                  Telefonszám / Phone number
                  <br/>
                  Jelölt értékelése / Applicant evaluation
                </td>
                <td>
                  Állásinterjú szervezése és lebonyolítása, kapcsolattartás /
                  Organising and conducting job interviews, maintaining contacts
                </td>
                <td>
                  Az Érintett hozzájárulása (GDPR 6. cikk (1) bek. a) pont) /
                  Consent of the Data Subject (Article 6 Section (1) point (a)
                  GDPR)
                </td>
                <td>
                  Munkaviszony létrehozása esetén a munkaviszony létrehozásáig,
                  munkaviszony létesítésének hiányában az Adatkezelő azon
                  döntéséig, hogy a Jelölttel nem létesít munkaviszonyt /
                  If an employment relationship is established, until the
                  employment relationship is established or, in the absence
                  of an employment relationship, until the Controller decides
                  not to establish an employment relationship with the
                  Applicant.
                </td>
              </tr>
              <tr>
                <td>
                  Név/ Name
                  <br/>
                  Pozíció / Position
                  <br/>
                  Bérigény / Salary request
                  <br/>
                  Önéletrajzban, motivációs levélben szereplő egyéb személyes
                  <br/>
                  adatok / Other personal data in CV, cover letter
                  <br/>
                  E-mail cím / E-mail address
                  <br/>
                  Telefonszám / Phone number
                  <br/>
                  Jelölt értékelése / Applicant evaluation
                </td>
                <td>
                  Amennyiben az Adatkezelő az első jelentkezést követően nem
                  létesített munkaviszonyt a Jelölttel, úgy a Jelölt
                  megkeresése későbbi állásajánlatokkal kapcsolatban /
                  If the Controller has not established an employment
                  relationship with the Applicant after the first application,
                  contacting the Applicant in relation to future job offers
                </td>
                <td>
                  Az Érintett hozzájárulása (GDPR 6. cikk (1) bek. a) pont) /
                  Consent of the Data Subject (Article 6 Section (1) point (a)
                  GDPR)
                </td>
                <td>
                  Az első jelentkezést, azaz az adatok felvételét követő 2
                  évig / For a period of 2 years after the first application,
                  i.e. after the data are recorded
                </td>
              </tr>
              <tr>
                <td>
                  Név / Name
                  <br/>
                  Munkaszerződésben szereplő egyéb személyes adatok (különösen:
                  születési hely, idő, anyja neve, TAJ-szám, bankszámlaszám,
                  munkabér, munkakör, munkaszerződés tartama) /
                  Other personal data included in the employment contract (in
                  particular: place and date of birth, mother's name, social
                  security number, bank account number, salary, job title,
                  duration of the employment contract)
                </td>
                <td>
                  Munkaszerződés létesítése /
                  Establishment of an employment contract
                </td>
                <td>
                  Az adatkezelés olyan szerződés teljesítéséhez szükséges,
                  amelyben az Érintett az egyik fél, vagy az a szerződés
                  megkötését megelőzően az Érintett kérésére történő lépések
                  megtételéhez szükséges (GDPR 6. cikk (1) bek. b) pont) /
                  Processing is necessary for the performance of a contract
                  to which the Data Subject is party or in order to take steps
                  at the request of the Data Subject prior to entering into a
                  contract (Article 6 Section (1) point (b) GDPR)
                </td>
                <td>
                  Munkaviszony létesítéséig /
                  Until the employment relationship is established
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row mt-5">
          <div className="col-lg-6 col-12">
            <h5>
              2. Adatfeldolgozás, adattovábbítás
            </h5>
            <p>
              Az Adatkezelő a Tájékoztató 1. pontjában meghatározott személyes
              adatok kezelésével kapcsolatban az alábbi adatfeldolgozókat veszi
              igénybe:
            </p>
            <p>
              Az Adatkezelő a Contabo Gmbh.-t (székhely: Aschauer Str. 32a,
              81549 München, Németország) veszi igénybe a Honlappal kapcsolatos
              tárhelyszolgáltatóként.
            </p>
            <p>
              Az Adatkezelő a személyes adatokat hatósági (bírósági) határozat
              alapján az arra jogosult szervnek továbbíthatja, mely esetben az
              adatkezelés jogalapja az Adatkezelőre vonatkozó jogi
              kötelezettség teljesítése.
            </p>
            <p>
              Az Adatkezelő személyes adatokat harmadik országba vagy
              nemzetközi szervezet részére nem továbbít.
            </p>
          </div>
          <div className="col-lg-6 col-12">
            <h5>
              2. Processing and transfer of data
            </h5>
            <p>
              The Controller uses the following data processors in connection
              with the processing of personal data as defined in point 1 of
              this Policy:
            </p>
            <p>
              The Controller uses Contabo Gmbh (headquarters: Aschauer Str.
              32a, 81549 Munich, Germany) as hosting provider for the Website.
            </p>
            <p>
              The Controller may transfer personal data to the competent
              authority on the basis of an official (court) decision, in
              which case the legal basis for the processing is the fulfilment
              of a legal obligation applicable to the Controller.
            </p>
            <p>
              The Controller does not transfer personal data to third countries
              or international organisations.
            </p>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-6 col-12">
            <h5>
              3. Automatizált döntéshozatal (ideértve a profilalkotást is):
            </h5>
            <p>
              A GDPR 4. cikk 4. pontja alapján profilalkotás a személyes adatok
              automatizált kezelésének bármely olyan formája, amelynek során a
              személyes adatokat valamely természetes személyhez fűződő
              bizonyos személyes jellemzők értékelésére, különösen a munkahelyi
              teljesítményhez, gazdasági helyzethez, egészségi állapothoz,
              személyes preferenciákhoz, érdeklődéshez, megbízhatósághoz,
              viselkedéshez, tartózkodási helyhez vagy mozgáshoz kapcsolódó
              jellemzők elemzésére vagy előrejelzésére használják. Az
              adatkezelés során automatizált döntéshozatalra, ideértve a
              profilalkotást is, nem kerül sor.
            </p>
          </div>
          <div className="col-lg-6 col-12">
            <h5>
              3. Automated decision-making (including profiling)
            </h5>
            <p>
              According to Article 4 point (4) of the GDPR, profiling means
              any form of automated processing of personal data consisting of
              the use of personal data to evaluate certain personal aspects
              relating to a natural person, in particular to analyse or predict
              aspects concerning that natural person's performance at work,
              economic situation, health, personal preferences, interests,
              reliability, behaviour, location or movements. No automated
              decision-making, including profiling, is involved in the
              processing.
            </p>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-6 col-12">
            <h5>
              4. Az Érintettek jogai
            </h5>
            <p>
              Az Érintett a jelen Tájékoztatóban leírt adatkezeléssel
              összefüggésben a GDPR III. fejezetében foglalt jogokat
              érvényesítheti az alábbiak szerint:
            </p>
            <p>
              Hozzájárulás visszavonása
              <br/>
              Az Érintett jogosult arra, hogy a személyes adatainak kezelésére
              vonatkozó hozzájárulását bármikor, indokolás nélkül visszavonja,
              anélkül, hogy ez az Érintettre nézve bármilyen anyagi vagy egyéb
              kötelezettséggel járna. A hozzájárulás visszavonása a
              visszavonásig terjedő adatkezelés jogszerűségét nem érinti.
            </p>
            <p>
              Hozzáféréshez és tájékoztatáshoz való jog
              <br/>
              Az Érintett jogosult arra, hogy visszajelzést kapjon arra
              vonatkozóan, hogy személyes adatainak kezelése folyamatban van-e,
              és amennyiben igen, az adatkezeléshez kapcsolódó információkhoz
              hozzáférést kapjon (adatkezelés célja, személyes adatok
              kategóriái, a személyes adatok forrására vonatkozó adatok stb.).
              Az Érintett továbbá másolatot kérhet az adatkezelés tárgyát
              képező személyes adatokról.
            </p>
            <p>
              Helyesbítés joga
              <br/>
              Az Érintett jogosult arra, hogy az Adatkezelő által kezelt
              személyes adatainak helyesbítését, ill. kiegészítését kérje.
            </p>
            <p>
              Törléshez való jog
              <br/>
              Az Érintett jogosult arra, hogy kérésére az Adatkezelő törölje az
              általa kezelt személyes adatokat, amennyiben az alábbi indokok
              valamelyike fennáll:
              <br/>
              <ul>
                <li>
                  a személyes adatokra már nincs szükség abból a célból,
                  amelyből azokat gyűjtötték vagy más módon kezelték;
                </li>
                <li>
                  az Érintett visszavonja az adatkezelés alapját képező
                  hozzájárulását, és az adatkezelésnek nincs más jogalapja;
                </li>
                <li>
                  az Érintett tiltakozik az adatkezelés ellen, és nincs
                  elsőbbséget élvező jogszerű ok az adatkezelésre;
                </li>
                <li>
                  a személyes adatokat jogellenesen kezelték;
                </li>
                <li>
                  a személyes adatokat az Adatkezelőre alkalmazandó uniós vagy
                  tagállami jogban előírt jogi kötelezettség teljesítéséhez
                  törölni kell;
                </li>
                <li>
                  a személyes adatok gyűjtésére az információs társadalommal
                  összefüggő szolgáltatások kínálásával kapcsolatosan került
                  sor.
                </li>
              </ul>
              <br/>
              A törlési jog ugyanakkor nem korlátlan, azt a vonatkozó uniós és
              hazai adatvédelmi jogszabályok előírásai korlátozhatják.
            </p>
            <p>
              Az adatkezelés korlátozásához való jog
              <br/>
              Az Érintett az alábbi esetekben jogosult az adatkezelés
              korlátozásának a kérésére:
              <ul>
                <li>
                  az Érintett vitatja a személyes adatok pontosságát, ez
                  esetben a korlátozás arra az időtartamra vonatkozik, amely
                  lehetővé teszi, hogy az Adatkezelő ellenőrizze a személyes
                  adatok pontosságát;
                </li>
                <li>
                  az adatkezelés jogellenes, és az Érintett az adatok törlése
                  helyett az adatkezelés korlátozását kéri;
                </li>
                <li>
                  az Adatkezelőnek nincs szükségük a személyes adatokra
                  adatkezelés céljából, de az Érintett igényli azokat jogi
                  igények előterjesztéséhez, érvényesítéséhez vagy védelméhez;
                  vagy
                </li>
                <li>
                  az Érintett tiltakozott az adatkezelés ellen; ez esetben a
                  korlátozás arra az időtartamra vonatkozik, amíg
                  megállapításra nem kerül, hogy az Adatkezelő jogos indokai
                  elsőbbséget élveznek-e az Érintettnek jogos indokaival
                  szemben.
                </li>
              </ul>
              Az adatkezelés korlátozását követően a korlátozás alá eső
              személyes adatokat a tárolás kivételével csak az Érintettek
              hozzájárulásával, vagy jogi igények előterjesztéséhez,
              érvényesítéséhez vagy védelméhez, vagy más természetes vagy
              jogi személy jogainak védelme érdekében, vagy az Unió ill.
              valamely tagállam fontos közérdekéből lehet kezelni.
              <br/>
              Az Érintetteknek továbbá joguk van arra, hogy az Adatkezelő
              rendelkezésére bocsátott személyes adataikat elektronikus úton
              megkapják, és azokat más adatkezelőnek továbbítsák.
            </p>
            <p>
              Tiltakozáshoz való jog
              <br/>
              Az Érintettnek jogában áll tiltakozni személyes adatainak
              kezelése ellen, ha az adatkezelés közvetlen üzletszerzés
              érdekében történik. Ebben az esetben a tiltakozás tárgyát
              képező adatok közvetlen üzletszerzés érdekében a továbbiakban
              nem kezelhetők.
              <br/>
              Panaszaival kapcsolatos ügyekben az Érintettnek jogában áll az
              illetékes adatvédelmi felügyeleti hatóságnál bejelentést tenni.
            </p>
          </div>
          <div className="col-lg-6 col-12">
            <h5>
              4. Rights of Data Subjects
            </h5>
            <p>
              In relation to the processing described in this Policy, the Data
              Subject may exercise the rights set out in Chapter III of the
              GDPR as follows:
            </p>
            <p>
              Withdrawal of consent
              <br/>
              The Data Subject is entitled to withdraw the consent to the
              processing of the personal data at any time, without giving any
              reason, and without any financial or other obligation for the
              Data Subject. Withdrawal of consent shall not affect the
              lawfulness of the processing until its withdrawal.
            </p>
            <p>
              Right of access and information
              <br/>
              The Data Subject has the right to receive information on whether
              their personal data are being processed and, if so, to have
              access to information related to the processing (purpose of
              processing, categories of personal data, source of personal data,
              etc.). The Data Subject may also request a copy of the personal
              data that are the subject of the processing.
            </p>
            <p>
              Right of correction
              <br/>
              The Data Subject is entitled to request the correction or
              completion of their personal data processed by the Controller.
            </p>
            <p>
              Right to delete
              <br/>
              The Data Subject's is entitled to request the Controller to
              delete the personal data processed by the Controller if one of
              the following grounds applies:
              <br/>
              <ul>
                <li>
                  the personal data are no longer necessary for the purposes
                  for which they were collected or otherwise processed;
                </li>
                <li>
                  the Data Subject withdraws the consent on which the
                  processing is based and there is no other legal basis for
                  the processing;
                </li>
                <li>
                  the Data Subject objects to the data processing and there are
                  no prior legitimate grounds for the data processing;
                </li>
                <li>
                  the personal data have been unlawfully processed
                </li>
                <li>
                  the personal data must be deleted in order to comply with a
                  legal obligation under Union or Member State law to which
                  the Controller is subject;
                </li>
                <li>
                  personal data are collected in connection with the provision
                  of information society services.
                </li>
              </ul>
              <br/>
              However, the right to delete data is not unlimited and may be
              limited by the requirements of applicable EU and national data
              protection laws.
            </p>
            <p>
              Right to limitation of processing
              <br/>
              The Data Subject has the right to request the limitation of
              processing in the following cases:
              <ul>
                <li>
                  the Data Subject disputes the accuracy of the personal data,
                  in which case the restriction applies for the period of time
                  necessary to allow the Controller to verify the accuracy of
                  the personal data;
                </li>
                <li>
                  the processing is unlawful and the Data Subject requests
                  limitation of the processing instead of deletion of the data;
                </li>
                <li>
                  the Controller does not need the personal data for the
                  purposes of processing, but the Data Subject requires
                  them for the establishment, exercise or defense of legal
                  claims; or
                </li>
                <li>
                  the Data Subject has objected to the processing; in this
                  case, the limitation shall apply for the period until it is
                  established whether the legitimate grounds of the Controller
                  prevail over the legitimate grounds of the Data Subject.
                </li>
              </ul>
              After the limitation of processing, the personal data subject to
              the limitation may be processed, except for archiving purposes,
              only with the consent of the Data Subjects for the
              establishment, exercise or defence of legal claims for the
              protection of the rights of another natural or legal person or
              for important public interests of the Union or of a Member State.
              <br/>
              In addition, Data Subjects have the right to receive their
              personal data provided to the Controller by electronic means
              and to transmit them to another controller.
            </p>
            <p>
              Right to object
              <br/>
              The Data Subject has the right to object to the processing of
              their personal data if the processing is for direct marketing
              purposes. In this case, the data that are the subject of the
              objection may no longer be processed for direct marketing
              purposes.
              <br/>
              In matters relating to the complaints, the Data Subject has the
              right to file a complaint with the competent data protection
              supervisory authority.
            </p>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-6 col-12">
            <h5>
              5. A joggyakorlás általános szabályai
            </h5>
            <p>
              Az Adatkezelő indokolatlan késedelem nélkül, de legfeljebb a
              kérelem beérkezésétől számított 30 napon belül tájékoztatja az
              Érintettet a kérelme nyomán hozott intézkedésekről. Szükség
              esetén, figyelembe véve a kérelem összetettségét és az Érintettek
              által előterjesztett kérelmek számát, ez a határidő további két
              hónappal meghosszabbítható. A határidő meghosszabbításáról az
              Adatkezelő a késedelem okainak megjelölésével a kérelem
              kézhezvételétől számított 30 napon belül tájékoztatja az
              Érintettet.
            </p>
            <p>
              Az Adatkezelő az Érintettek részére a tájékoztatást és
              intézkedést díjmentesen biztosítja. Ha az Érintett kérelme
              egyértelműen megalapozatlan vagy – különösen ismétlődő jellege
              miatt – túlzó, az Adatkezelő, figyelemmel a kért információ vagy
              tájékoztatás nyújtásával vagy a kért intézkedés meghozatalával
              járó adminisztratív költségekre:
            </p>
            <p>
              a) észszerű összegű díjat számíthat fel, vagy
            </p>
            <p>
              b) megtagadhatja a kérelem alapján történő intézkedést.
            </p>
            <p>
              A kérelem egyértelműen megalapozatlan vagy túlzó jellegének
              bizonyítása az Adatkezelőt terheli.
            </p>
            <p>
              Ha az Adatkezelőnek megalapozott kétségei vannak a kérelmet
              benyújtó természetes személy kilétével kapcsolatban, további,
              az Érintett személyazonosságának megerősítéséhez szükséges
              információk nyújtását kérhetik.
            </p>
          </div>
          <div className="col-lg-6 col-12">
            <h5>
              5. General rules on the exercise of rights
            </h5>
            <p>
              The Controller shall inform the Data Subject of the measures
              taken in response to the request without undue delay, but no
              later than 30 days from the receipt of the request. If necessary,
              taking into account the complexity of the request and the number
              of requests made by the Data Subjects, this time limit may be
              extended by a further two months. The Controller shall inform
              the Data Subject of the extension of the time limit within 30
              days of receipt of the request, stating the reasons for the delay.
            </p>
            <p>
              The Controller shall provide the Data Subjects with information
              free of charge. If the Data Subject's request is clearly
              unfounded or excessive, in particular because of its repetitive
              nature, the Controller shall, taking into account the
              administrative costs of providing the requested information or
              taking the requested action:
            </p>
            <p>
              a) charge a reasonable fee, or
            </p>
            <p>
              b) refuse to act on the request.
            </p>
            <p>
              The responsibility for proving that the request is clearly
              unfounded or excessive falls on the Controller.
            </p>
            <p>
              If the Controller has reasonable doubts as to the identity of the
              natural person making the request, it may request additional
              information necessary to confirm the identity of the Data Subject.
            </p>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-6 col-12">
            <h5>
              6. Jogérvényesítési lehetőségek
            </h5>
            <p>
              Az Érintett a személyes adatai kezelésével kapcsolatban bármikor
              az Adatkezelő képviselőjéhez, Zelczer Tamás ügyvezetőhöz
              fordulhat. Az Adatkezelő képviselőjének elérhetősége:
            </p>
            <p>
              e-mail cím: privacy@cursorinsight.com
            </p>
            <p>
              Az Adatkezelő a beérkező kérelmeket a jelen Tájékoztató 4.
              pontjában meghatározott módon és határidőben köteles
              megválaszolni.
            </p>
            <p>
              Az Érintett a jogainak megsértése esetén az Adatkezelővel szemben
              bírósághoz fordulhat. A bíróság az ügyben soron kívül jár el.
              Azt, hogy az adatkezelés a jogszabályban foglaltaknak megfelel,
              az Adatkezelő köteles bizonyítani. A per elbírálása a
              törvényszék, a fővárosban a Fővárosi Törvényszék hatáskörébe
              tartozik.
            </p>
            <p>
              Az Érintett a személyes adatai kezelésével kapcsolatos panasz
              esetén a Nemzeti Adatvédelmi és Információszabadság Hatósághoz is
              fordulhat (dr. Péterfalvi Attila, a Nemzeti Adatvédelmi és
              Információszabadság Hatóság elnöke, postai cím: 1530 Budapest,
              Pf.: 5., cím: 1055 Budapest, Falk Miksa u. 9-11., Telefon: +36
              (30) 683 5969; E-mail: ugyfelszolgalat@naih.hu; honlap:
              www.naih.hu).
            </p>
            <p>
              Az Adatkezelő fenntartja a Tájékoztató módosításának jogát
              fenntartja, melyről az Érintetteket tájékoztatja.
            </p>
          </div>
          <div className="col-lg-6 col-12">
            <h5>
              6. Enforcement options
            </h5>
            <p>
              The Data Subject may contact the Controller's representative,
              Tamás Zelczer, managing director, at any time in connection with
              the processing of the personal data. Contact details of the
              Controller's representative:
            </p>
            <p>
              e-mail address: privacy@cursorinsight.com
            </p>
            <p>
              The Controller shall respond to requests received in the manner
              and within the time limits set out in point 4 of this Policy.
            </p>
            <p>
              The Data Subject may take the Controller to court in the event
              of a breach of his or her rights. The court proceeds forthwith
              in the case. The Controller shall prove that the processing
              complies with the law. The trial of the case is in the
              jurisdiction of the court, in the capital the Metropolitan Court.
            </p>
            <p>
              In the event of a complaint regarding the processing of personal
              data, the Data Subject may also contact the National Authority
              for Data Protection and Freedom of Information (Dr. Attila
              Péterfalvi, Director of the National Authority for Data
              Protection and Freedom of Information, postal address: 1530
              Budapest, PO Box 5, address: 1055 Budapest, Falk Miksa u. 9-11.,
              phone: +36 (30) 683 5969; e-mail: ugyfelszolgalat@naih.hu;
              website: www.naih.hu).
            </p>
            <p>
              The Controller reserves the right to modify this Privacy and
              shall inform the Data Subjects thereof.
            </p>
          </div>
        </div>
      </Section>
    </PageLayout>
  );
};

export default Gdpr;
