import React from 'react';
import { Redirect, useParams } from 'react-router-dom';

import PageLayout from '../../../layouts/PageLayout';
import useTags from '../hooks/useTags';
import { ContainerSpinner } from '../../../components/Spinner';

const TagRedirect = () => {
  const { tag } = useParams();
  const { isLoading, tags } = useTags({ tag });

  if (isLoading) {
    return (
      <PageLayout>
        <ContainerSpinner />
      </PageLayout>
    );
  }

  if (tags[0]) {
    return <Redirect to={tags[0].redirect} />;
  }

  return <Redirect to="/blog/1" />;
};

export default TagRedirect;
