import Section from '../../../../components/Section';
import styles from './Intro.module.scss';

import logo from './logo.png';

function Intro() {
  return (
    <Section
      title="Meet the Leaders in Human Motion Analysis"
      className={styles.Intro}
    >
      <div className="row">
        <div className="col-12 mb-5">
          <img src={logo}/>
        </div>
      </div>
      <div className="row">
        <div className="col-12  mb-5">
          <h3>
            The only validated feature extractor accurately describing human
            motion.
            <br />
            {' '}
            Perfected for 15 years and used by millions today.
          </h3>
        </div>
      </div>
  </Section>
  );
}

export default Intro;
