import PropTypes from 'prop-types';

import styles from './PageTitle.module.scss';

const PageTitle = ({ children }) => (
  <h1 className={styles.PageTitle}>{children}</h1>
);

PageTitle.propTypes = {
  children: PropTypes.string.isRequired,
};

export default PageTitle;
