import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import axios from 'axios';

export default function useDocument() {
  const { documentSlug } = useParams();
  const history = useHistory();
  const [title, setTitle] = useState();
  const [document, setDocument] = useState();


  useEffect(() => {
    setDocument(undefined);
    setTitle(undefined);

    axios.get(`/documents/${documentSlug}.md`)
      .then((response) => response.data)
      .then((text) => {
        setTitle(text.split('\n')[0].replace(new RegExp('#', 'g'), '').trim());
        setDocument(text);
      })
      .catch(() => history.push('/'));
  }, [documentSlug, history, setTitle, setDocument]);

  return { title, document };
}
