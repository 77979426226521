import React from 'react'

import PageLayout from '../../layouts/PageLayout';
import Section from '../../components/Section';
import Seo from '../../components/Seo';

import styles from './BiometricEsignatureBanking.module.scss';

import WhoWeAre from '../IndexPage/sections/WhoWeAre';
import Testimonials from '../IndexPage/sections/Testimonials';

import signo from './signo.png';
import icon1 from './icon1.png';
import icon2 from './icon2.png';
import icon3 from './icon3.png';
import signowise from './signowise.svg';
import dfk from './dfk.png';
import pipe from './pipe.svg';

const BiometricEsignatureBanking = () => {
  return (
    <PageLayout>
      <Seo title="Cursor Insight | Biometric Esignature Banking" />
      <div className={styles.BiometricEsignatureBanking}>
        <Section
          title="Biometric E-Signatures in Banking"
        >
          <div className="container">
            <div className="row mb-5 pb-5">
              <div className="col-12 text-center">
                <div className={styles.Intro}>
                  <div className={styles.Card}>
                    <img src={signo} alt="SignoWise" width={180} />
                  </div>
                  <p><strong>Customer:</strong> Large Bank in the CEE Region</p>
                  <p>
                    <strong>Goal:</strong> Digitizing the contracting process
                    in bank branches
                  </p>
                  <p>
                    <strong>Solution:</strong>
                    {' '}
                    <a href="https://signowise.com" target="_blank">
                      SignoWise Enterprise
                    </a>
                  </p>
                  <a
                    href="https://calendly.com/tamas-cursor-insight/consultation
                    "
                    target="_blank"
                    className="ButtonPrimary mt-3"
                  >
                    Schedule a call
                  </a>
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12 text-center">
                <div className={styles.Element}>
                  <h2>Challenges</h2>
                  <div className="row">
                    <div className="col-lg-4 col-12">
                      <img src={icon1} alt="icon1" />
                      <p>
                        The bank must verify clients' signatures by comparing
                        them to the specimen signatures. Yet, analyzing
                        signatures by bank tellers is time-consuming, quite
                        inaccurate, and vulnerable to abuse.
                      </p>
                    </div>
                    <div className="col-lg-4 col-12">
                      <img src={icon2} alt="icon2" />
                      <p>
                        Tens of thousands of documents daily require
                        collaboration among multiple bank employees and clients
                        in different locations. Banks aspire to handle these
                        documents exclusively digitally to enhance efficiency
                        and reduce paper consumption.
                      </p>
                    </div>
                    <div className="col-lg-4 col-12">
                      <img src={icon3} alt="icon3" />
                      <p>
                        Managing digital documents presents challenges,
                        including ensuring the legal validity of e-signatures
                        and handling biometric data. This involves complex
                        tasks such as data protection in compliance with
                        regulations like eIDAS and GDPR.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12 text-center">
                <div className={styles.Element}>
                  <h2>Solution</h2>
                  <p>
                    <a
                      href="https://signowise.com"
                      target="_blank"
                      className="mainlink"
                    >
                      SignoWise
                    </a>
                    {' '}
                    is a biometric electronic signature system
                    developed by Cursor Insight.
                  </p>
                </div>
                <div className={styles.Center}>
                  <div className="my-5">
                    <a href="https://signowise.com" target="_blank">
                      <img
                        src={signowise}
                        alt="SignoWise"
                        width={550}
                        className={styles.SignoWise}
                      />
                    </a>
                  </div>
                  <div className="my-5">
                    <p className="text-center mt-3">
                      Winner of SigWiComp
                      <br />
                      by the German Research Center for AI
                    </p>
                    <img src={dfk} alt="DFK" width={180} />
                  </div>
                </div>
                <div className={styles.Element}>
                  <div className="row">
                    <div className="col-lg-4">
                      <div className={styles.Icons}>
                        <img src={icon2} alt="icon2" />
                        <img src={pipe} alt="pipe" />
                      </div>
                      <p>
                        We can connect thousands of signature devices to our
                        system. In addition, SignoWise works with iOS and
                        Android tablets, tablet PCs, and dedicated signature
                        pads, and it can be integrated easily into any document
                        management system.
                      </p>
                    </div>
                    <div className="col-lg-4">
                      <div className={styles.Icons}>
                        <img src={icon3} alt="icon3" />
                        <img src={pipe} alt="pipe" />
                      </div>
                      <p>
                        SignoWise applies multiple digital signatures to
                        documents, including the signature device identifier,
                        timestamps, and encrypted biometrics signatures to
                        ensure compliance with eIDAS and GDPR.
                      </p>
                    </div>
                    <div className="col-lg-4">
                      <div className={styles.Icons}>
                        <img src={icon1} alt="icon1" />
                        <img src={pipe} alt="pipe" />
                      </div>
                      <p>
                        SignoWise automatically verifies the genuineness of
                        signatures with our award-winning technology. Our
                        process prevents the theft or the re-use of a signature
                        placed on a document. It’s faster, much more accurate
                        than a handwriting expert and works even with an
                        unreliable internet connection.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-5 text-center">
              <div className="col-12">
                <a
                  href="https://calendly.com/tamas-cursor-insight/consultation"
                  target="_blank"
                  className="ButtonPrimary mt-3"
                >
                  Schedule a call
                </a>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-12 mt-5">
                <div className={styles.Results}>
                  <h2>Results</h2>
                  <div className="row mt-5">
                    <div className="col-lg-4 col-md-6 col-12 mb-5 text-center">
                      <div className={styles.ResultItem}>
                        <p>Number of users</p>
                        <h3>2M+</h3>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 mb-5 text-center">
                      <div className={styles.ResultItem}>
                        <p>Handled documents daily</p>
                        <h3>20K+</h3>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 mb-5 text-center">
                      <div className={styles.ResultItem}>
                        <p>Signature devices in branches</p>
                        <h3>4000</h3>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 text-center">
                      <div className={styles.ResultItem}>
                        <p>Signature verification accuracy</p>
                        <h3>99.7%</h3>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 text-center">
                      <div className={styles.ResultItem}>
                        <p>Verification process time</p>
                        <h3>0.09 sec</h3>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 text-center">
                      <div className={styles.ResultItem}>
                        <p>Time saved on each transaction</p>
                        <h3>10 mins</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <Testimonials />
        <WhoWeAre />
      </div>
    </PageLayout>
  );
}

export default BiometricEsignatureBanking;
