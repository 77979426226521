import { Link } from 'react-router-dom';
import consultation from './consultation.gif';

import {
  Form,
  TextInput,
  CheckBox,
  SubmitButton,
} from 'custom-form-client/react';

import {
  CUSTOM_FORM_URL,
  GRABOXY_CAPTCHA_SITE_KEY,
  CAPTCHA_URL_BASE,
  GRABOXY_API_KEY,
  TRAP_SERVER_URL,
} from '../../constants';

import Section from '../Section';
import BookMeeting from '../BookMeeting';
import styles from './ContactForm.module.scss';

const ContactForm = () => (
  <>
    <Section className={`py-5 ${styles.Roadmap}`} title="Cooperation Roadmap">
      <div className="row">
        <div className="col-lg-6 text-white">
          <h4 className="mb-3 mt-4">
            <strong>1. Initial Contact & Use-Case Selection</strong>
          </h4>
          <p>
            Our process starts with a discussion of your specific goals,
            and choosing the most relevant use-cases to meet your objectives.
          </p>
          <h4 className="mb-3 mt-4">
            <strong>2. Proof of Concept with Your Data</strong>
          </h4>
          <ul>
            <li>We provide data collection software</li>
            <li>Your data stays securely on your premises</li>
            <li>Our AI toolset analyzes the anonymized data</li>
            <li>We deliver prediction models for your evaluation</li>
          </ul>
          <h4 className="mb-3 mt-4">
            <strong>3. Implement solution</strong>
          </h4>
          <ul>
            <li>Integrate a scalable solution into your architecture</li>
            <li>Supports both Cloud and On-prem environments</li>
            <li>Standard API responses provided for quick implementation</li>
            <li>
              E2E solutions that can be combined with and enhance your current
              systems
            </li>
          </ul>
          <h4 className="mb-3 mt-4">
            <strong>4. Post-go-live support</strong>
          </h4>
          <ul>
            <li>Work with a team that’s there for your long-term success</li>
          </ul>
        </div>
        <div className="col-lg-6 text-white">
          <img src={consultation} alt="" className="d-none d-md-block" />
          <BookMeeting />
        </div>
      </div>
    </Section>
    <Section
      id="ContactForm"
      title="Get in touch"
      className={`py-5 ${styles.ContactForm}`}
    >
      <div className={styles.FormBox}>
        <Form
          id="customForm"
          type="contact"
          className={styles.Form}
          action={CUSTOM_FORM_URL}
          responseMessage="Thank you for your message."
          captchaType="graboxyCaptcha"
          captchaSiteKey={GRABOXY_CAPTCHA_SITE_KEY}
          captchaOptions={{
            urlBase: CAPTCHA_URL_BASE,
            apiKey: GRABOXY_API_KEY,
            trapUrl: TRAP_SERVER_URL,
          }}
        >
          <TextInput
            id="name"
            label="Name"
            className="mb-4"
            required
          />

          <TextInput
            id="email"
            type="email"
            label="Email"
            className="mb-4"
            required
          />

          <TextInput
            id="company"
            label="Company"
            className="mb-4"
          />

          <TextInput
            id="message"
            label="Message"
            className="mb-4"
            multiline
          />

          <CheckBox id="subscribe">
            Send me news about product updates
          </CheckBox>

          <CheckBox id="accept" className="mb-4" required>
            I agree to the
            {' '}
            <Link
              to="/document/privacy-policy"
              target="_blank"
              className="text-nowrap"
            >
              Privacy Policy
            </Link>
            <small>(Required)</small>
          </CheckBox>

          <SubmitButton className={styles.ButtonPrimary}>
            Send
          </SubmitButton>
        </Form>
      </div>
      <div className="row text-center mt-3">
        <div className="col-12">
          <p>
            For marketing-related inquiries, get in touch with
            {' '}
            <a href="mailto:marketing@cursorinsight.com">our marketing team.</a>
          </p>
        </div>
        <div className="col-12">
          <p className="mb-0">
            To discuss potential investments, please reach out to
            {' '}
            <a href="mailto:investing@cursorinsight.com">our founders.</a>
          </p>
        </div>
      </div>
    </Section>
  </>
);

export default ContactForm;
