import React from 'react';
import PropTypes from 'prop-types';

import styles from './Avatar.module.scss';

const Avatar = ({ src, name }) => {
  if (src.indexOf('gravatar') < 0) {
    return (
      <img
        src={src}
        alt="Avatar"
        className={styles.Avatar}
      />
    );
  }

  return (
    <div className={styles.Letter}>
      {name.slice(0, 1).toLowerCase()}
    </div>
  );
};

Avatar.propTypes = {
  src: PropTypes.string,
  name: PropTypes.string.isRequired,
};

Avatar.defaultProps = {
  src: 'gravatar',
};

export default Avatar;
