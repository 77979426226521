import { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import Footer from '../../../components/Footer';
import Seo from '../../../components/Seo';
import Header from '../../../components/Header';

const BlogLayout = ({ children }) => {
  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  });

  return (
    <>
      <Seo />
      <Header />
      <main>{children}</main>
      <Footer withContact={false} />
    </>
  );
};

BlogLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BlogLayout;
