import DocumentLayout from '../../layouts/DocumentLayout';
import Section from '../../components/Section';
import Markdown from '../../components/Markdown';
import Seo from '../../components/Seo';
import useDocument from './useDocument';

const DocumentPage = () => {
  const {title, document} = useDocument();

  return (
    <DocumentLayout>
      <Seo title={`Cursor Insight | ${title}`} />
      <Section className="mb-5" maxWidth={992}>
        <Markdown>{document}</Markdown>
      </Section>
    </DocumentLayout>
  );
};

export default DocumentPage;
