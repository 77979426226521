import PropTypes from 'prop-types';

import LogoHeader from '../components/LogoHeader';
import BaseLayout from './BaseLayout';

const DocumentLayout = ({ children }) => (
  <BaseLayout>
    <LogoHeader />
    <main>{children}</main>
  </BaseLayout>
);

DocumentLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DocumentLayout;
