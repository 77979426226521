import { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import Footer from '../components/Footer';
import Seo from '../components/Seo';
import ScrollToTop from '../components/ScrollToTop';

const BaseLayout = ({ children }) => {
  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  });

  return (
    <>
      <Seo />
      {children}
      <ScrollToTop />
      <Footer />
    </>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BaseLayout;
