import { NavLink } from 'react-router-dom';
import Logo from '../Logo';
import ContactButton from './ContactButton';
import styles from './Navbar.module.scss';
import './Mobilemenu.scss';
import cursor from '../../images/cursor.svg';

const Navbar = () => (
  <div className="container">
    <nav className={`navbar fixed-top navbar-expand-lg navbar-dark ${styles.Navbar}`}>
        <div className="container">
          <img src={cursor} alt="cursor" className={styles.Cursor} />
          <NavLink to="/" spy="true" smooth="true" className="navbar-brand">
            <span className={styles.NavLogo}>
              <Logo />
            </span>
          </NavLink>

          <input
            id="menu__toggle"
            type="checkbox"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          />
          <label class="menu__btn" for="menu__toggle">
            <span></span>
          </label>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              <li className="nav-item mx-0 mx-lg-3">
                <NavLink to="/solutions" className="nav-link">
                  Solutions
                </NavLink>
                <ul className="w-100">
                  <div>
                    <li>
                      <h5>Cybersecurity</h5>
                    <a
                      href="https://graboxy.com/solutions/continuous-authentication"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Continuous authentication
                    </a>
                    <span>Graboxy</span>
                    <a
                      href="https://graboxy.com/solutions/multi-factor-authentication"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Silent 2FA
                    </a>
                    <span>Graboxy</span>
                    <a
                      href="https://graboxy.com/solutions/device-fingerprinting"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Device Fingerprinting
                    </a>
                    <span>Graboxy</span>
                    <a
                      href="https://graboxy.com/solutions/remote-desktop-detection"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Remote Desktop Detection
                    </a>
                    <span>Graboxy</span>
                    <a
                      href="https://graboxy.com/solutions/captcha-bot-detection"
                      rel="noreferrer"
                      target="_blank"
                    >
                      CAPTCHA & Bot detection
                    </a>
                    <span>Graboxy</span>
                    </li>
                    <li>
                      <h5>eHealth</h5>
                      <a
                        href="/MotionAnalysisHealth"
                        rel="noreferrer"
                        target="_blank"
                      >
                        Clinical Diagnostics
                      </a>
                      <span>MotionAnalysis.health</span>
                      <a
                        href="https://www.cursorinsight.com/updrs-assessment-demo"
                        rel="noreferrer"
                        target="_blank"
                      >
                        Parkinson's Assessment
                      </a>
                      <span>MotionAnalysis.health</span>
                    </li>
                  </div>
                  <div>
                    <li>
                      <h5>Biometrics</h5>
                      <a
                        href="https://signowise.com"
                        rel="noreferrer"
                        target="_blank"
                      >
                        Biometric e-Signatures
                      </a>
                      <span>SignoWise</span>
                      <a
                        href="https://www.motionscore.ai"
                        rel="noreferrer"
                        target="_blank"
                      >
                        Credit scoring and default prediction
                      </a>
                      <span>MotionScore</span>
                      <a
                        href="https://www.cursorinsight.com/walking-recognition"
                        rel="noreferrer"
                        target="_blank"
                      >
                        CCTV gait recognition
                      </a>
                      <span>Walking Recognition</span>
                      <a
                        href="https://www.cursorinsight.com/sensor-video-analysis"
                        rel="noreferrer"
                        target="_blank"
                      >
                        3D Movement Analysis
                      </a>
                      <span>Fitness Coach and Rowerwatch</span>
                    </li>
                    <li>
                      <h5>Gaming</h5>
                      <a
                        href="https://anti-cheat.graboxy.com/#/"
                        rel="noreferrer"
                        target="_blank"
                      >
                        Aimbot detection
                      </a>
                      <span>Graboxy</span>
                    </li>
                  </div>
                </ul>
              </li>
              <li className="nav-item mx-0 mx-lg-3 position-relative">
                <NavLink to="/use-cases" className="nav-link">
                  Use cases
                </NavLink>
                <ul>
                  <li>
                    <a href="/banking-solutions">
                      Banking
                    </a>
                    <a href="/healthcare">
                      Healthcare
                    </a>
                    <a href="/government">
                      Government
                    </a>
                    <a href="/law-enforcement">
                      Law Enforcement
                    </a>
                    <a href="/cybersecurity">
                      Cybersecurity
                    </a>
                    <a href="/gaming">
                      Gaming
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item mx-0 mx-lg-3">
                <NavLink to="/technology" className="nav-link">
                  Technology
                </NavLink>
              </li>
              <li className="nav-item mx-0 mx-lg-3 position-relative">
                <NavLink to="/resources" className="nav-link">
                  Resources
                </NavLink>
                <ul>
                  <li>
                    <a href="/resources#use-cases">
                      Case Studies
                    </a>
                    <a href="/resources#blog">
                      Blog
                    </a>
                    <a href="/resources#whitepapers">
                      Whitepaper
                    </a>
                    <a href="/resources#scientific-papers">
                      Scientific Papers
                    </a>
                    <a href="/resources#posters">
                      Posters
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <NavLink to="/our-team" className="nav-link">
                  Team
                </NavLink>
              </li>
            </ul>

            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <ContactButton className={styles.ContactButton} />
              </li>
            </ul>
          </div>
        </div>
    </nav>
  </div>
);

export default Navbar;
