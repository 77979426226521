import PropTypes from 'prop-types';
import Navbar from '../Navbar';
import styles from './Header.module.scss';

const Header = ({ children }) => (
  <header className={styles.Header}>
    <div className={styles.Container}>
      <Navbar />
      {children}
    </div>
  </header>
);

Header.propTypes = {
  children: PropTypes.node,
}

Header.defaultProps = {
  children: undefined,
};

export default Header;
