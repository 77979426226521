import PropTypes from 'prop-types';

import styles from './Award.module.scss';

const Award = ({ image, title, link, className }) => {
  if (link) {
    return (
      <div className={`px-2 ${styles.Award}`}>
        <div>
          <img src={image} alt={title} className={className} />
        </div>
        <a href={link} target="_blank">
          <p>{title}</p>
        </a>
      </div>
    );
  }

  return (
    <div className={`px-2 ${styles.Award}`}>
      <div>
        <img src={image} alt={title} className={className} />
      </div>

      <p>{title}</p>
    </div>
  );
};

Award.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  link: PropTypes.string,
  className: PropTypes.string,
};

Award.defaultProps = {
  link: undefined,
  className: undefined,
};

export default Award;
