import React, { useState } from "react";
import Section from '../../../../components/Section';
import styles from './Accordion.module.scss';
import './Accordion.scss';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import backward from './gifs/send-backward.gif';
import palette from './gifs/pantone-color-palette.gif';
import artificial from './gifs/artificial-intelligence-ai.gif';
import data from './gifs/data.gif';
import audio from './gifs/rhythm-audio-equalizer.gif';

const Accordion = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const toggleAccordion = (index) => {
    setActiveIndex(index);
  };

  const accordionData = [
    {
      title: "Methodology",
      content: (
        <div className="container py-3">
          <div className="row text-center mb-4 px-5">
            <div className="col-5">
              <h4>Our Approach to Movement Analysis</h4>
            </div>
            <div className="col-2"></div>
            <div className="col-5">
              <h4>Typical Approach of Others</h4>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-5 py-2 px-0 text-center">
              <div className="hexagon hexagon-left">
                <h4>
                  Device-specific sensor data preprocessing
                  to reconstruct original movement
                </h4>
              </div>
            </div>
            <div className="col-md-2 py-2 px-0">
              <div className="text-center">
                <h3 className="mt-2">Sensor Data PreProcessing</h3>
                <img src={backward} alt="" width="70" />
              </div>
            </div>
            <div className="col-md-5 py-2 px-0 text-center">
              <div className="hexagon hexagon-right">
                <h4>Standard, general purpose data smoothing methods</h4>
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-5 py-2 px-0 text-center">
              <div className="hexagon hexagon-left">
                <h4>
                  Delicate balance of bespoke features artfully emulating
                  key elements of human motor programs
                </h4>
              </div>
            </div>
            <div className="col-md-2 py-2 px-0">
              <div className="text-center">
                <h3 className="mt-2">Feature<br />Space</h3>
                <img src={palette} alt="" width="70" />
              </div>
            </div>
            <div className="col-md-5 py-2 px-0 text-center">
              <div className="hexagon hexagon-right">
                <h4>
                  Standard physical features, or observable,
                  domain-specific features
                </h4>
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-5 py-2 px-0 text-center">
              <div className="hexagon hexagon-left">
                <h4>Proprietary AI toolchain designed for motion analysis</h4>
              </div>
            </div>
            <div className="col-md-2 py-2 px-0">
              <div className="text-center">
                <h3 className="mt-2">Machine Learning</h3>
                <img src={artificial} alt="" width="70" />
              </div>
            </div>
            <div className="col-md-5 py-2 px-0 text-center">
              <div className="hexagon hexagon-right">
                <h4>
                  Popular, general purpose Python AI libraries
                  or statistics modules
                </h4>
              </div>
            </div>
          </div>

          <div className="row mt-5 text-center">
            <a href="/technology" className="mainlink d-block w-auto mx-auto">Learn More</a>
          </div>
        </div>
      )
    },
    {
      title: "Data Scientist-led Projects",
      content: (
        <div className="container py-3">
          <div className="row text-center mb-4 px-5">
            <div className="col-5">
              <h4>Our Approach to Movement Analysis</h4>
            </div>
            <div className="col-2"></div>
            <div className="col-5">
              <h4>Typical Approach of Others</h4>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-5 py-2 px-0 text-center">
              <div className="hexagon hexagon-left">
                <h4>
                  Possibility to handpick features when
                  only limited amount of data is available
                </h4>
              </div>
            </div>
            <div className="col-md-2 py-2 px-0">
              <div className="text-center">
                <h3 className="mt-2">Data Size</h3>
                <img src={data} alt="" width="70" />
              </div>
            </div>
            <div className="col-md-5 py-2 px-0 text-center">
              <div className="hexagon hexagon-right">
                <h4>Deep Learning on large amount of data only</h4>
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-5 py-2 px-0 text-center">
              <div className="hexagon hexagon-left">
                <h4>Explainable models built on meaningful features</h4>
              </div>
            </div>
            <div className="col-md-2 py-2 px-0">
              <div className="text-center">
                <h3 className="mt-2">Prediction<br />Models</h3>
                <img src={audio} alt="" width="70" />
              </div>
            </div>
            <div className="col-md-5 py-2 px-0 text-center">
              <div className="hexagon hexagon-right">
                <h4>Unexplainable “Black Box” models</h4>
              </div>
            </div>
          </div>

          <div className="row mt-5 text-center">
            <a href="/technology" className="mainlink d-block w-auto mx-auto">Learn More</a>
          </div>
        </div>
      )
    },
    {
      title: "Further Advantages",
      content: (
        <div>
          <h4>
            <strong>Cross-domain knowledge</strong> from having
            analyzed:
          </h4>
          <ul>
            <li>handwriting</li>
            <li>cursor movement</li>
            <li>video-based fine and gross motor movements</li>
            <li>other time-series data</li>
          </ul>
          <h4 className="mt-4 pt-2">
            <strong>Millions of users</strong> served by our solutions
            across:
          </h4>
          <ul>
            <li>user identification</li>
            <li>signature verification</li>
            <li>personality profiling</li>
            <li>assessing neurological conditions</li>
          </ul>
          <div className="row mt-5 text-center">
            <a href="/technology" className="mainlink d-block w-auto mx-auto">Learn More</a>
          </div>
        </div>
      )
    }
  ];

  return (
    <Section title="Why we are the leaders in Motion Analysis">
      <div className="accordion py-5 only-desktop">
        {accordionData.map((item, index) => (
          <div
            key={index}
            className={`accordion-item ${index === activeIndex ? "active" : ""}`}
            onClick={() => toggleAccordion(index)}
          >
            <div className="accordion-title">
              {item.title}
            </div>
            <div className="accordion-content">
              {item.content}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.OnlyMobile}>
        <div className="row mobiletable">
          <div className="col-12">
            <div className={styles.Table}>
              <Tabs
                defaultActiveKey="methodology"
              >
                <Tab eventKey="methodology" title="Methodology">
                  <div className={styles.mobileRow}>
                    <h3>Sensor Data PreProcessing</h3>
                    <h4>Our Approach to Movement Analysis</h4>
                    <p>
                      <strong>Device-specific</strong> sensor data
                      preprocessing to reconstruct original movement
                    </p>
                    <h4 className={styles.Grey}>Typical Approach of Others</h4>
                    <p className={styles.Grey}>
                      Standard, general purpose data smoothing methods
                    </p>
                  </div>
                  <div className={styles.mobileRow}>
                    <h3>Feature Space</h3>
                    <h4>Our Approach</h4>
                    <p>
                      Delicate balance of bespoke features artfully
                      {' '}
                      <strong>emulating</strong>
                      {' '}
                      key elements of
                      {' '}
                      <strong>human motor programs</strong>
                    </p>
                    <h4 className={styles.Grey}>Typical Approach of Others</h4>
                    <p className={styles.Grey}>
                      Standard physical features, or observable,
                      domain-specific features
                    </p>
                  </div>
                  <div className={styles.mobileRow}>
                    <h3>Machine Learning</h3>
                    <h4>Our Approach</h4>
                    <p>
                      <strong>Proprietary AI</strong>
                      {' '}
                      toolchain designed for motion analysis
                    </p>
                    <h4 className={styles.Grey}>Typical Approach of Others</h4>
                    <p className={styles.Grey}>
                      Popular, general purpose Python AI libraries or
                      statistics modules
                    </p>
                  </div>
                  <div className="text-center">
                    <a href="/technology" className="ButtonPrimary">
                      Learn more
                    </a>
                  </div>
                </Tab>
                <Tab eventKey="datascience" title="Data Scientist-led Projects">
                  <div className={styles.mobileRow}>
                    <h3>Data Size</h3>
                    <h4>Our Approach to Movement Analysis</h4>
                    <p>
                      Possibility to <strong>handpick features</strong> when
                      only limited amount of data is available
                    </p>
                    <h4 className={styles.Grey}>
                      Typical Data Scientist-led Projects
                    </h4>
                    <p className={styles.Grey}>
                      Deep Learning on large amount of data only
                    </p>
                  </div>
                  <div className={styles.mobileRow}>
                    <h3>Prediction Models</h3>
                    <h4>Our Approach</h4>
                    <p>
                      <strong>Explainable models</strong> built on meaningful
                      features
                    </p>
                    <h4 className={styles.Grey}>Typical Approach of Others</h4>
                    <p className={styles.Grey}>
                      Unexplainable “Black Box” models
                    </p>
                  </div>
                  <div className="text-center">
                    <a href="/technology" className="ButtonPrimary">
                      Learn more
                    </a>
                  </div>
                </Tab>
                <Tab eventKey="further" title="Further Advantages">
                  <div className={styles.mobileRow}>
                    <h4>
                      <strong>Cross-domain knowledge</strong> from having
                      analyzed:
                    </h4>
                    <ul>
                      <li>handwriting</li>
                      <li>cursor movement</li>
                      <li>video-based fine and gross motor movements</li>
                      <li>other time-series data</li>
                    </ul>
                  </div>
                  <div className={styles.mobileRow}>
                    <h4>
                      <strong>Millions of users</strong> served by our solutions
                      across:
                    </h4>
                    <ul>
                      <li>user identification</li>
                      <li>signature verification</li>
                      <li>personality profiling</li>
                      <li>assessing neurological conditions</li>
                    </ul>
                  </div>
                  <div className="text-center">
                    <a href="/technology" className="ButtonPrimary">
                      Learn more
                    </a>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Accordion;
