import React, { useEffect, useState } from 'react';

import styles from './ScrollToTop.module.scss';

const ScrollToTop = () => {

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };

  return (
    <>
      {showButton && (
        <button onClick={scrollToTop} className={styles.ScrollToTop}>
          <i class="fa fa-chevron-up" aria-hidden="true"></i>
        </button>
      )}
    </>
  );

};

export default ScrollToTop;
