import React from 'react'
import { Link } from 'react-scroll';

import PageLayout from '../../layouts/PageLayout';
import Section from '../../components/Section';
import Seo from '../../components/Seo';

import styles from './Cybersecurity.module.scss';

import mazeVideoMp4 from '../../videos/record.mp4';

import WhoWeAre from '../IndexPage/sections/WhoWeAre';
import Partners from '../IndexPage/sections/Partners';
import Testimonials from '../IndexPage/sections/Testimonials';

const Cybersecurity = () => {
  return (
    <PageLayout>
      <Seo title="Cursor Insight | Cybersecurity Solutions" />
      <div className={styles.Cybersecurity}>
        <Section
          title="Cybersecurity Solutions"
        >
          <div className="container">
            <div className="row mb-5 pb-5">
              <div className="col-12 text-center">
                <div className={styles.cyberIntro}>
                  <p>
                    Eliminate identity fraud and prevent corporate &amp;
                    customer account takeovers. Detect bots and catch cheaters
                    in computer games.
                  </p>
                  <p>
                    <Link
                      className="mainlink"
                      to="ContactForm"
                      smooth="true"
                    >
                      Get in touch
                    </Link>
                    if you want to learn more.
                  </p>
                  <a
                    href="https://calendly.com/tamas-cursor-insight/consultation"
                    rel="noreferrer"
                    target="_blank"
                    className="ButtonPrimaryLg"
                  >
                    Book a meeting
                  </a>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 mb-5">
                <div className="business-card sentinel">
                  <div>
                    <h3>Online account takeover prevention</h3>
                    <p>
                      Identify users continuously based on their cursor
                      movement dynamics through browsers and prevent online
                      account takeover fraud with Graboxy Sentinel Online.
                      Unlike most types of biometrics, mouse movement
                      characteristics cannot be stolen, shared, or successfully
                      replicated. Maximize the safety of your customers during
                      the whole user session while keeping the UX frictionless.
                    </p>
                    <ul>
                      <li>
                        Works in browsers
                      </li>
                      <li>
                        Invisible for users
                      </li>
                      <li>
                        Seamless integration
                      </li>
                    </ul>
                    <a
                      href="https://graboxy.com"
                      target="_blank"
                      className="ButtonPrimary"
                    >
                      Learn more
                    </a>
                  </div>
                  <div>
                    <div className="iframe-container">
                      <iframe
                        src="https://www.youtube.com/embed/h5biJ-D959o?controls=0"
                        frameborder="0"
                        allow="autoplay; picture-in-picture; web-share"
                        allowfullscreen
                        title="iframe-container"
                      >
                      </iframe>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mb-5">
                <div className="business-card twofa">
                  <div>
                    <h3>Workforce authentication</h3>
                    <p>
                      Remote work increases the risk of corporate account
                      takeovers and data breaches. Authenticate employees
                      continuously in the background based on their cursor
                      movement dynamics. These kinds of cursor biometrics
                      cannot be stolen or replicated. Reach your Zero Trust
                      goals with Graboxy Sentinel Desktop.
                    </p>
                    <ul>
                      <li>
                        Seamless integration
                      </li>
                      <li>
                        NIS2 &amp; SOC2 compliant
                      </li>
                      <li>
                        Invisible for users
                      </li>
                    </ul>
                    <a
                      href="https://graboxy.com"
                      target="_blank"
                      className="ButtonPrimary"
                    >
                      Learn more
                    </a>
                  </div>
                  <div>
                    <div className="iframe-container">
                      <iframe
                        src="https://www.youtube.com/embed/DcanTk-cCps?controls=0"
                        frameborder="0"
                        allow="autoplay; picture-in-picture; web-share"
                        allowfullscreen
                        title="iframe-container"
                      >
                      </iframe>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mb-5">
                <div className="business-card captcha">
                  <div>
                    <h3>Multi-factor authentication</h3>
                    <p>
                      Authenticate users invisibly in the background of the
                      user session with Graboxy Sentinel or use an optional
                      5-second movement challenge with Graboxy 2FA. No need to
                      send costly SMS codes for the users to switch between
                      devices and apps. We turn cursor movements into biometric
                      profiles that cannot be stolen or imitated.
                    </p>
                    <ul>
                      <li>
                        No need for additional hardware
                      </li>
                      <li>
                        Easily brandable design
                      </li>
                      <li>
                        Cost efficient
                      </li>
                      <li>
                        PSD2 and SCA compliant
                      </li>
                    </ul>
                    <a
                      href="https://graboxy.com"
                      target="_blank"
                      className="ButtonPrimary"
                    >
                      Learn more
                    </a>
                  </div>
                  <div>
                    <div className="iframe-container">
                      <iframe
                        src="https://www.youtube.com/embed/Hog8cVGuQY0?controls=0"
                        frameborder="0"
                        allow="autoplay; picture-in-picture; web-share"
                        allowfullscreen
                        title="iframe-container"
                      >
                      </iframe>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mb-5">
                <div className="business-card captcha">
                  <div>
                    <h3>Captcha</h3>
                    <p>
                      Solve a simple maze in just 2 seconds and we’ll know
                      whether you are a human. Graboxy CAPTCHA lets you boost
                      your brand and make CAPTCHAs fun for users. Replace fire
                      hydrants and traffic lights with your brand. Free for up
                      to 1 million monthly requests.
                    </p>
                    <ul>
                      <li>
                        Fun and easy to complete
                      </li>
                      <li>
                        Easily brandable design
                      </li>
                      <li>
                        Simple integration
                      </li>
                      <li>
                        Personal data are not stored
                      </li>
                    </ul>
                    <a
                      href="https://graboxy.com"
                      target="_blank"
                      className="ButtonPrimary"
                    >
                      Learn more
                    </a>
                  </div>
                  <div>
                    <div className={styles.VideoContainer}>
                      <video className="video" autoPlay muted loop>
                        <source src={mazeVideoMp4} type="video/mp4" />
                        Your browser does not support HTML video.
                      </video>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <WhoWeAre />
        <Partners />
        <Testimonials />
      </div>
    </PageLayout>
  );
}

export default Cybersecurity;
