import React from 'react'
import { Link } from 'react-scroll';

import PageLayout from '../../layouts/PageLayout';
import Section from '../../components/Section';
import Seo from '../../components/Seo';

import styles from './Government.module.scss';

import mazeVideoMp4 from '../../videos/record.mp4';

import WhoWeAre from '../IndexPage/sections/WhoWeAre';
import Partners from '../IndexPage/sections/Partners';
import Testimonials from '../IndexPage/sections/Testimonials';

const Government = () => {
  return (
    <PageLayout>
      <Seo title="Cursor Insight | Solutions for Government Agencies" />
      <div className={styles.Government}>
        <Section
          title="Solutions for Government Agencies"
        >
          <div className="container">
            <div className="row mb-5 pb-5">
              <div className="col-12 text-center">
                <div className={styles.governmentIntro}>
                  <p>
                    Protect confidential data, and authenticate employees &amp;
                    users. Enhance your physical security system, and
                    investigate criminal cases with our human motion analysis
                    technology.
                  </p>
                  <p>
                    <Link
                      className="mainlink"
                      to="ContactForm"
                      smooth="true"
                    >
                      Get in touch
                    </Link>
                    if you want to learn more.
                  </p>
                  <a
                    href="https://calendly.com/tamas-cursor-insight/consultation"
                    rel="noreferrer"
                    target="_blank"
                    className="ButtonPrimaryLg"
                  >
                    Book a meeting
                  </a>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 mb-5">
                <div className="business-card twofa">
                  <div>
                    <h3>Forensic analysis</h3>
                    <p>
                      Our gait analysis system enables quick suspect
                      recognition, speeds up crime solving and increases the
                      accuracy of suspect identification. Walking Recognition
                      is designed to generate biometric profiles by analyzing
                      existing CCTV footage and video records. Gait recognition
                      complements or replaces the most common biometric
                      identification methods in forensic science, such as DNA,
                      fingerprint, and facial recognition.
                    </p>
                    <a
                      href="/forensic-analysis"
                      rel="noreferrer"
                      className="ButtonPrimary"
                    >
                      Learn more
                    </a>
                  </div>
                  <div>
                    <div className="iframe-container">
                      <iframe
                        src="https://player.vimeo.com/video/922004834?h=e589af612e"
                        title="Cursor Insight"
                        frameborder="0"
                        allow="autoplay; picture-in-picture; web-share"
                        allowfullscreen
                      >
                      </iframe>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mb-5">
                <div className="business-card twofa">
                  <div>
                    <h3>Workforce authentication</h3>
                    <p>
                      Remote work increases the risk of corporate account
                      takeovers and data breaches. Authenticate employees
                      continuously in the background based on their cursor
                      movement dynamics. These kinds of cursor biometrics
                      cannot be stolen or replicated. Reach your Zero Trust
                      goals with Graboxy Sentinel Desktop.
                    </p>
                    <ul>
                      <li>
                        Seamless integration
                      </li>
                      <li>
                        NIS2 &amp; SOC2 compliant
                      </li>
                      <li>
                        Invisible for users
                      </li>
                    </ul>
                    <a
                      href="https://graboxy.com"
                      target="_blank"
                      className="ButtonPrimary"
                    >
                      Learn more
                    </a>
                  </div>
                  <div>
                    <div className="iframe-container">
                      <iframe
                        src="https://www.youtube.com/embed/DcanTk-cCps?si=CqpDDT34DXub_J4D?controls=0"
                        frameborder="0"
                        allow="autoplay; picture-in-picture; web-share"
                        allowfullscreen
                      >
                      </iframe>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mb-5">
                <div className="business-card sentinel">
                  <div>
                    <h3>Online account takeover prevention</h3>
                    <p>
                      Identify users continuously based on their cursor
                      movement dynamics through browsers and prevent online
                      account takeover fraud with Graboxy Sentinel Online.
                      Unlike most types of biometrics, mouse movement
                      characteristics cannot be stolen, shared, or successfully
                      replicated. Maximize the safety of your customers during
                      the whole user session while keeping the UX frictionless.
                    </p>
                    <ul>
                      <li>
                        Works in browsers
                      </li>
                      <li>
                        Invisible for users
                      </li>
                      <li>
                        Seamless integration
                      </li>
                    </ul>
                    <a
                      href="https://graboxy.com"
                      target="_blank"
                      className="ButtonPrimary"
                    >
                      Learn more
                    </a>
                  </div>
                  <div>
                    <div className="iframe-container">
                      <iframe
                        src="https://www.youtube.com/embed/h5biJ-D959o?si=13MrTUcQY4QfIpOC?controls=0"
                        frameborder="0"
                        allow="autoplay; picture-in-picture; web-share"
                        allowfullscreen
                      >
                      </iframe>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mb-5">
                <div className="business-card captcha">
                  <div>
                    <h3>Multi-factor authentication</h3>
                    <p>
                      Fight identity theft and account takeover fraud with
                      motion-based authentication. Authenticate users with a
                      simple cursor movement challenge in just 5 seconds. No
                      need to send costly SMS codes for the users to switch
                      between devices and apps.
                    </p>
                    <ul>
                      <li>
                        No need for additional hardware
                      </li>
                      <li>
                        Easily brandable design
                      </li>
                      <li>
                        Cost efficient
                      </li>
                      <li>
                        PSD2 and SCA compliant
                      </li>
                    </ul>
                    <a
                      href="https://graboxy.com"
                      target="_blank"
                      className="ButtonPrimary"
                    >
                      Learn more
                    </a>
                  </div>
                  <div>
                    <div className="iframe-container">
                      <iframe
                        src="https://www.youtube.com/embed/Hog8cVGuQY0?controls=0"
                        frameborder="0"
                        allow="autoplay; picture-in-picture; web-share"
                        allowfullscreen
                      >
                      </iframe>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mb-5">
                <div className="business-card captcha">
                  <div>
                    <h3>Captcha</h3>
                    <p>
                      Solve a simple maze in just 2 seconds and we’ll know
                      whether you are a human. Graboxy CAPTCHA lets you boost
                      your brand and make CAPTCHAs fun for users. Replace fire
                      hydrants and traffic lights with your brand. Free for up
                      to 1 million monthly requests.
                    </p>
                    <ul>
                      <li>
                        Fun and easy to complete
                      </li>
                      <li>
                        Easily brandable design
                      </li>
                      <li>
                        Simple integration
                      </li>
                      <li>
                        Personal data are not stored
                      </li>
                    </ul>
                    <a
                      href="https://graboxy.com"
                      target="_blank"
                      className="ButtonPrimary"
                    >
                      Learn more
                    </a>
                  </div>
                  <div>
                    <div className={styles.VideoContainer}>
                      <video className="video" autoPlay muted loop>
                        <source src={mazeVideoMp4} type="video/mp4" />
                        Your browser does not support HTML video.
                      </video>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mb-5">
                <div className="business-card ci">
                  <div>
                    <h3>Walking Recognition</h3>
                    <p>
                      Our CCTV gait recognition technology analyzes how people
                      walk on live video footage. Enhance your physical
                      security and identify unauthorized persons entering
                      premises or use it for investigations to recognize people
                      whose faces cannot be seen.
                    </p>
                    <ul>
                      <li>
                        Gross motor analysis with 3D modeling
                      </li>
                      <li>
                        No need to invest in new hardware
                      </li>
                      <li>
                        Sensitive biometric features are not stored
                      </li>
                    </ul>
                    <a
                      href="https://www.cursorinsight.com/walking-recognition"
                      target="_blank"
                      className="ButtonPrimary"
                    >
                      Learn more
                    </a>
                  </div>
                  <div>
                    <div className="iframe-container">
                      <iframe
                        src="https://player.vimeo.com/video/922004834?h=e589af612e"
                        frameborder="0"
                        allow="autoplay; picture-in-picture; web-share"
                        allowfullscreen
                      >
                      </iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <WhoWeAre />
        <Partners />
        <Testimonials />
      </div>
    </PageLayout>
  );
}

export default Government;
