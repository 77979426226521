import PageLayout from '../../layouts/PageLayout';
import Seo from '../../components/Seo';


const Contact = () => (
  <PageLayout>
    <Seo title="Cursor Insight | Contact" />
  </PageLayout>
);

export default Contact;
