import { Link } from 'react-scroll';

import Section from '../Section';
import styles from './RealTimeVideo.module.scss';

const GetInTouch = () => (
  <Section className="my-5" maxWidth={1170}>
    <div className="row px-0 px-md-2">
      <div className="col-12 px-0 px-md-2">
        <div className={styles.GetInTouch}>
          <div className={styles.GetInTouchInner}>
            <h3>
              What can our AI
              <br/>
              uncover from
              <br />
              real-time video?
            </h3>

            <Link to="ContactForm" className="ButtonPrimary">
              Get in touch
            </Link>
          </div>
          <div className={styles.GetInTouchInner}>
            <ul>
              <li>
                Recognize people based on body dimensions and gait
              </li>
              <li>
                Physical and cyber security for critical workstations when
                combined with
                {' '}
                <a
                  href="https://graboxy.com"
                  target="_blank"
                  className="mainlink"
                >
                  Graboxy
                </a>
              </li>
              <li>
                Gross motor analysis with 3D modelling
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </Section>
);

export default GetInTouch;
