import React from 'react';

import PageLayout from '../../layouts/PageLayout';
import Seo from '../../components/Seo';

import Accordion from '../IndexPage/sections/Accordion';

// Inner components
import MyTab from './components/Tab';
import Intro from './components/Intro';
import Tables from './components/Tables';
import Numbers from './components/Numbers';
import AboutUs from './components/AboutUs';
import Journey from './components/Journey';
import Team from './components/Team';

import styles from './MotionAnalysisHealth.module.scss';

const MotionAnalysisHealthPage = () => {

  return (
    <PageLayout>
      <Seo title="Cursor Insight | MotionAnalysis.health" />
      <div className={styles.MotionAnalysisHealth}>
        <Intro />
        <Accordion />
        <Tables />
        <Numbers />
        <MyTab />
        <AboutUs />
        <Journey />
        <Team />
      </div>
    </PageLayout>
  );
};

export default MotionAnalysisHealthPage;
