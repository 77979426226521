import React from 'react';

import styles from './BookMeeting.module.scss';

const BookMeeting = () => (
  <div className={styles.BookMeeting}>
    <h4>
      <strong>Book an online meeting</strong>
    </h4>
    <p>
      Let us know how we can help.
      <br />
      Book a free consultation now!
    </p>

    <a
      href="https://calendly.com/tamas-cursor-insight/consultation"
      target="_blank"
      rel="noreferrer"
      className="ButtonPrimaryLg"
    >
      Book now
    </a>
  </div>
);

export default BookMeeting;
