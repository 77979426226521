import BaseLayout from '../../layouts/BaseLayout';
import Header from '../../components/Header';
import Hero from '../../components/Hero';
import Technology from './sections/Technology';
import Video from './sections/Video';
import BlogTeaser from '../Blog/components/BlogTeaser';
import Solutions from './sections/Solutions';
import GetInTouch from '../../components/GetInTouch';
import WhoWeAre from './sections/WhoWeAre';
import Partners from './sections/Partners';
import Hiring from './sections/Hiring';
import Testimonials from './sections/Testimonials';
import Featured from './sections/Featured';
import Accordion from './sections/Accordion';

const IndexPage = () => (
  <BaseLayout>
    <Header>
      <Hero />
    </Header>
    <main style={{ overflowX: 'hidden'}}>
      <Technology />
      <Video />
      <BlogTeaser />
      <Testimonials />
      <Solutions />
      <Accordion />
      <GetInTouch />
      <WhoWeAre />
      <Hiring />
      <Partners />
      <Featured />
    </main>
  </BaseLayout>
);

export default IndexPage;
