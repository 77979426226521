import React from 'react';
import { Link } from 'react-router-dom';
import ReactCookieConsent from 'react-cookie-consent';

import styles from './CookieConsent.module.scss';

const CookieConsent = () => (
  <ReactCookieConsent
    location="bottom"
    buttonText="Consent"
    cookieName="Graboxy Captcha Landing"
    containerClasses={styles.CookieConsent}
    style={{ background: '#132735' }}
    buttonClasses={`ButtonPrimary ${styles.Button}`}
    expires={150}
  >
    This website uses
    {' '}
    <Link to="/document/cookie-policy">
      cookies
    </Link>
    {' '}
    to enhance the user experience.
  </ReactCookieConsent>
);

export default CookieConsent;
