import PropTypes from 'prop-types';

import styles from './Section.module.scss';

const Section = ({ id, title, className, maxWidth, children }) => (
  <section id={id} className={className}>
    <div className="container mx-auto" style={{ maxWidth }}>
      {title &&
        <h2 className={`mb-4 mx-auto ${styles.SectionTitle}`}>
          {title.split('|').map((rows, i) => (
            <font key={i}>{rows}<br/></font>
          ))}
        </h2>
      }
      {children}
    </div>
  </section>
);

Section.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  maxWidth: PropTypes.number,
  children: PropTypes.node,
};

Section.defaultProps = {
  id: undefined,
  title: undefined,
  className: undefined,
  maxWidth: undefined,
  children: undefined,
};

export default Section;
