import React from 'react';
import Slider from 'react-slick';

import Section from '../../../../components/Section';
import Testimonial from './Testimonial';
import TESTIMONIALS from './Testimonials.data';
import './Testimonials.scss';

const SETTINGS = {
  dots: true,
  arrows: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  infinite: true,
  swipeToSlide: true,
  speed: 200,
  autoplay: true,
  autoplaySpeed: 3500,
  pauseOnFocus: true,
  cssEase: 'ease',
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Testimonials = () => {
  const renderedTestimonials = TESTIMONIALS.map((testimonial) => (
    <Testimonial key={testimonial.name} {...testimonial} />
  ));

  return (
    <Section title="What they say about us" className="Testimonials mt-5 pt-5">
      <div className="row">
        <div className="col-12">
          <Slider {...SETTINGS}>
            {renderedTestimonials}
          </Slider>
        </div>
      </div>
    </Section>
  );
};

export default Testimonials;
