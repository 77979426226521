import React from 'react';
import Section from '../../../../components/Section';

import styles from './Journey.module.scss';

const journeyData = [
  {
    date: '1999-2003',
    title: 'The Spark',
    description: 'We are excited by the birth of the idea that the way in which a person moves a computer mouse has individual characteristics, like handwriting. What if we could develop software with the capability to automatically extract and analyze these attributes? The potential applications could be breathtaking.',
  },
  {
    date: '2003-2006',
    title: 'The First Step',
    description: 'We create a cursor-movement-monitoring tool with low-frequency, low-quality movement data. We run multiple cursor co-ordinate time series, visualizing the paths in the hope of identifying the visual individual characteristics.',
  },
  {
    date: '2006-2011',
    title: 'Widening Our Scope',
    description: 'We work on identifying further movement pattern characteristics, such as full body movement based on video footage, increasing the potential for identifying individuals through these features.',
  },
  {
    date: '2011-2015',
    title: 'The Birth of Cursor Insight',
    description: 'Can personality traits be predicted via cursor movements? Our technology is applied in multiple diverse scenarios, repeatedly proving its accuracy and robustness.',
  },
  {
    date: '2015-2020',
    title: 'Biometrics and Fintech',
    description: 'Cursor Insight enjoys huge success in the world of Fintech, with more than two million users accessing our SignoWise e-signature solution system. Our clients trust us to process tens of millions of e-signatures per year, saving themselves and their customers precious time with every transaction.',
  },
  {
    date: '2020-',
    title: 'e-Health A World-Changing Vision',
    description: 'We begin the first explorations in applying Motion Analysis to health. We process camera footage to analyze walking patterns, running forms and hand tremors. We capture eye movements, electrocardiograms and galvanic skin response. We look forward to a world of possibilities for our revolutionary technology, reimagining how we can meet health challenges to empower patients and healthcare professionals.',
  },
];

const Journey = () => {
  return (
    <Section className={styles.Journey} title="Our Journey" maxWidth={1080}>
      <div className="row pt-5"></div>
      {journeyData.map((item, index) => (
        <div key={index} className={`row ${styles.timelineItem} position-relative`}>
          {index % 2 === 0 ? (
            <>
              <div className="col-md-5 mb-md-5 mb-3">
                <div className={styles.Right}>
                  <div>
                    <p>{item.date}</p>
                  </div>
                  <div>
                    <h3 className={styles.Title}>{item.title}</h3>
                  </div>
                  <div>
                    <p className={styles.Description}>{item.description}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-2 d-flex justify-content-center position-relative">
                <div className={styles.TimelineLine}></div>
                <div className={styles.TimelineDot}></div>
              </div>
              <div className="col-md-5 mb-md-5 mb-3"></div>
            </>
          ) : (
            <>
              <div className="col-md-5 mb-md-5 mb-3"></div>
              <div className="col-md-2 d-flex justify-content-center position-relative">
                <div className={styles.TimelineLine}></div>
                <div className={styles.TimelineDot}></div>
              </div>
              <div className="col-md-5 mb-5 mb-md-0">
                <div className={styles.Left}>
                  <div>
                    <p>{item.date}</p>
                  </div>
                  <div>
                    <h3 className={styles.Title}>{item.title}</h3>
                  </div>
                  <div>
                    <p className={styles.Description}>{item.description}</p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      ))}
    </Section>
  );
};

export default Journey;
